import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../config';

export default function useTestResult(testTypeId, releaseId) {

  const fetchTestResult = async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}test_results`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        },
        params: {
          test_type_id: testTypeId,
          release_id: releaseId,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to load projects in useTestResult', error); // Error during fetch
      throw error;
    }
  };

  const queryKey = ['test_result', releaseId, testTypeId];

  const { data: testResult = {}, isLoading, refetch, error } = useAuthenticatedQuery(queryKey, fetchTestResult, [PROJECT_SCOPE], {
      staleTime: 5 * 60 * 1000, // Data will be cached for 5 minutes
  });

  // Additional logs to monitor the state changes
  if (error) {
    console.error('Error fetching test result:', error);
  }
  
  return { testResult, isLoading, refetch };
}