import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionHeader, AccordionBody, Card, Typography } from '@material-tailwind/react';
import { Title } from '@tremor/react';

import SeverityChip from './SeverityChip';
import { MdExpandMore, MdExpandLess } from "react-icons/md";

const IssuesList = ({ title, issues, components }) => {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  
  return (
    <Card className='mt-6'>
        {title && <Title>{title}</Title>}

        {issues && issues.length > 0 ? (
            issues.map((issue, index) => (
            <Accordion key={issue.key} open={open === index + 1}>
                <AccordionHeader className="text-base font-normal" onClick={() => handleOpen(index + 1)}>
                <div className="grid items-center w-full" style={{ gridTemplateColumns: '1fr auto auto' }}>
                    
                    <Typography variant='lead' className="pl-3 flex-grow border-r border-gray-300" style={{ whiteSpace: 'normal' }}>
                      {issue.message}
                    </Typography >
                    <div className="flex justify-end pl-3 pr-3 mr-3">
                      <SeverityChip severity={issue.severity} />
                    </div>
                    
                    <div className=" border-l border-gray-300">
                      {open === index + 1 ? <MdExpandLess size={35} className='ml-3'/> : <MdExpandMore size={35} className='ml-3'/>}
                    </div>
                </div>
                </AccordionHeader>
                <AccordionBody className='p-3 bg-gray-100'>
                  <Typography variant='paragraph'><strong>File:</strong> {issue.path}</Typography>
                  <Typography variant='paragraph'><strong>Line number:</strong> {issue.line}</Typography>
                  <Typography variant='paragraph'><strong>Effort:</strong> {issue.effort}</Typography>
                </AccordionBody>
            </Accordion>
            ))
        ) : (
            <p>No issues to display</p>
        )}
    </Card>
  );
};

IssuesList.propTypes = {
  title: PropTypes.string,
  issues: PropTypes.array,
  components: PropTypes.array,
};

export default IssuesList;