import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-tailwind/react';
import { getSeverityColour, getAccessibilityViolationColours } from '../../../datahooks/metadata/DataUtils';

const SeverityChip = ({ severity, isAccessibility = false }) => {  
  const chipColor = isAccessibility ? getAccessibilityViolationColours(severity) : getSeverityColour(severity);

  return (
    <Chip color={chipColor} value={severity}>
      {severity}
    </Chip>
  );
};

SeverityChip.propTypes = {
  severity: PropTypes.string,
  isAccessibility: PropTypes.bool
};

export default SeverityChip;