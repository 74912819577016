import React from 'react';
import PropTypes from 'prop-types';

const FooterLink = ({ url, children }) => {
  return (
    <a 
      href={url} 
      className=" hover:underline font-light flex items-center justify-center my-2" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

FooterLink.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node,
};

export default FooterLink;