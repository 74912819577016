import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../config';

export default function useProjectTrends(testTypeId, projectId, metric) {

  const fetchTrend = async (token) => {
    try {
      const params = {
        test_type_id: testTypeId,
        project_id: projectId,
      };

      // Add the metric to params only if it is provided and not null/undefined
      // For all test types, the default metric will be returned if metric is not provided
      // Metric is used in SCA and Performance test type only
      if (metric) {
        params.metric = metric;
      }

      const response = await axios.get(`${API_BASE_URL}project_trends`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        },
        params: params, // Only include metric if it's defined
      });
      return response.data;
    } catch (error) {
      console.error('Failed to load project trends in useProjectTrends', error); // Error during fetch
      throw error;
    }
  };

  const queryKey = ['project_trends', projectId, testTypeId, metric];

  const { data: projectTrend = {}, isLoading, refetch, error } = useAuthenticatedQuery(queryKey, fetchTrend, [PROJECT_SCOPE], {
      staleTime: 5 * 60 * 1000, // Data will be cached for 5 minutes
  });
  
  return { projectTrend, isLoading, isError: error, refetch };
}
