import React from 'react';
import PropTypes from 'prop-types';
import {  Typography, IconButton } from '@material-tailwind/react';
import { FaEdit } from 'react-icons/fa';

import { ErrorCard, CARD_TYPES } from '../../cards/ErrorCard';

import DisableDialog from '../general/DisableDialog';

const ProjectTestTypesTable = ({ projectTestTypesData = [], onDeleteTestType, onEditTestType}) => {

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold">Project Test Types</h3>
            </div>


        {projectTestTypesData.length === 0 ? (
            <div className="flex flex-col justify-center items-center p-4">
                <ErrorCard cardType={CARD_TYPES.EMPTY} />
                <Typography variant='h6'>No test types found. Add a new one to get started.</Typography>
            </div>
        ) : (
            <div className="flex flex-col space-y-2">
                    <div className="flex justify-between font-bold bg-gray-200 p-2 gap-2">
                    <div className="w-10/12">Test Type</div>
                    <div className="w-1/12"></div> 
                    <div className="w-1/12"></div> 
                    </div>

                    {projectTestTypesData?.map((test_type) => (
                        <div className="flex justify-between p-2 gap-2" key={test_type.id}>
                            <div className="w-10/12">{test_type.title}</div>
                            <IconButton 
                                className="w-1/12"
                                color='blue' 
                                variant='text'
                                alt={`Edit test type?`}
                                onClick={() => onEditTestType(test_type.id)}
                            >
                                <FaEdit size={22} />
                            </IconButton>
                            <div className="w-1/12">
                                <DisableDialog 
                                    showText={false} 
                                    theId={test_type.id} 
                                    theStatus='active' 
                                    theType='Project Test Type' 
                                    onConfirm={onDeleteTestType} 
                                />
                            </div>
                        </div>
                    ))
                    }
            </div>
        )}
        </div>
    );
};
ProjectTestTypesTable.propTypes = {
    projectTestTypesData: PropTypes.array,
    onDeleteTestType: PropTypes.func,
    onEditTestType: PropTypes.func
};
export default ProjectTestTypesTable;
