import { Typography, Chip } from "@material-tailwind/react";
import PropTypes from 'prop-types';
import OverviewCardWrapper from "./OverviewCardWrapper";
import { Grid, Card, Title  } from "@tremor/react";
import { getStatusDescription, getTimeDifference, convertDateToLocal, featureDetails } from "../../../datahooks/metadata/DataUtils";

import { MdOutlineTimer } from "react-icons/md";

export default function OverviewFirstRow({ releaseId, title, intro, releaseData, releaseTitle, releaseStatus, releasedIntoTest, releaseStartDate, releaseLastDate, targetToLive, doChangeTab }) {

    const statusInfo = getStatusDescription(releaseStatus);

    const overviewCards = Object.entries(releaseData).map(([testTypeKey, overviewData]) => {
        const testTypeIdString = testTypeKey.split('_')[2];
        const testTypeId = parseInt(testTypeIdString, 10);

        if (isNaN(testTypeId)) {
            throw new Error(`Invalid number extracted from string: ${testTypeIdString}`);
        }
    
        if (featureDetails[testTypeId]) {
            return (
                <OverviewCardWrapper 
                    testTypeId={testTypeId} 
                    overviewData={overviewData} 
                    key={`OverviewCard_${releaseId}_${testTypeKey}`} 
                    onClick={() => doChangeTab(featureDetails[testTypeId].title)}
                />
            );
        } else {
            console.log(`testTypeId ${testTypeId} not found in featureDetails`);
            return null;
        }
    }).filter(Boolean); // Filter out any null values, keeping only valid components

  return (
    <>
        <Card className="flex flex-col h-full w-full mb-3">
    <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex flex-col w-full lg:w-auto lg:flex-1">
            <Title className="text-xl mb-1 lg:mb-0">
                {title}
            </Title>
        </div>
        <div className="flex flex-row gap-2 lg:justify-end lg:w-auto flex-wrap">
            <Chip color='blue' value={convertDateToLocal(releaseLastDate, true, true)} />
            <Chip color='blue' value={getTimeDifference(releaseStartDate, releaseLastDate)} icon={<MdOutlineTimer size={20}/>} />
            <Chip color='blue' value={releaseTitle} />
            <Chip color={statusInfo.colour} value={statusInfo.description} />
        </div>
    </div>
    <div className="mt-2">
        <Typography variant="paragraph" className="w-full">
            {intro}
        </Typography>
    </div>
    <div className="mt-2 flex flex-row flex-wrap items-center">
        {releasedIntoTest && (
            <div className="flex flex-row items-center mb-2 lg:mb-0">
                Released into test:
                <Typography className="ml-3 font-medium" variant="paragraph">
                    {releasedIntoTest}
                </Typography>
            </div>
        )}
        {targetToLive && (
            <div className="flex flex-row items-center ml-5">
                Target release to Live:
                <Typography className="ml-3 font-medium" variant="paragraph">
                    {targetToLive}
                </Typography>
            </div>
        )}
    </div>
</Card>



        <Grid numItemsMd={2} numItemsLg={3} className="gap-6 mb-5">
             {overviewCards}
        </Grid>
    </>
  );
}

OverviewFirstRow.propTypes = {
    releaseId: PropTypes.string,
    title: PropTypes.string,
    intro: PropTypes.string,
    releaseData: PropTypes.object,
    releaseTitle: PropTypes.string,
    releaseStatus: PropTypes.string,
    releasedIntoTest: PropTypes.string,
    releaseStartDate: PropTypes.string,
    releaseLastDate: PropTypes.string,
    targetToLive: PropTypes.string,
    doChangeTab: PropTypes.func
};