import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_ADMIN_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import useUser from '../../useUser.js';
import { convertDateToLocal } from '../../metadata/DataUtils.js';

export default function useEditUser(userId = null, addingUser = false) {
  const { user: userDetails } = useUser();

  const fetchEditUser = async (token) => {
    if (addingUser || userId === -1) {
      return null; // Return null if adding a new user or invalid userId
    }

    try {
      const response = await axios.get(`${API_ADMIN_URL}user?edit_user_id=${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY,
        },
      });

      const editUser = response.data;

      if (editUser.last_login) {
        editUser.last_login = convertDateToLocal(editUser.last_login, true, true);
      }

      if (editUser.creation) {
        editUser.creation = convertDateToLocal(editUser.creation, true, true);
      }

      return editUser;

    } catch (error) {
      console.error('Failed to load user in useEditUser', error); // Error during fetch
      throw error;
    }
  };

  const { data: editUserData = null, isLoading, refetch, error } = useAuthenticatedQuery(
    `edit_user_${userDetails?.id}_${userId}`,
    fetchEditUser,
    [PROJECT_SCOPE],
    {
      staleTime: 60000, //Data is considered fresh for 1 minute. As we refetch after an mutation, this could be set to infinity.
      enabled: !addingUser && userId !== -1, // Only enable the query if not adding a new user and userId is valid
    }
  );

  // Additional logs to monitor the state changes
  if (error) {
    console.error('Error fetching edit user:', error);
  }

  return { editUserData, loadingEditUser: isLoading, refetchEditUser: refetch, editUserError: error };
}

useEditUser.propTypes = {
  userId: PropTypes.number,
  addingUser: PropTypes.bool,
};