import PopExplanation from './PopExplanation';
import PropTypes from 'prop-types';

export default function PopAccessibility({ page = false, size=35 }) {

    return (
        <PopExplanation 
            title="Accessibility" 
            size={size}
            metric1Title={page ? "" : "Total Pages Tested"}
            metric1Desc={page ? "Makes sure our app can be used by everyone, including those with disabilities. Follows design standards for an easy-to-use interface for all." : "Shows the number of web pages evaluated for accessibility, indicating the scope of the accessibility assessment."}
            metric2Title={page ? "" : "% of Pages that Pass/Fail"}
            metric2Desc={page ? "" : "Reflects the percentage of pages meeting or not meeting accessibility standards, providing insight into overall web accessibility compliance."} />
    );
}

PopAccessibility.propTypes = {
    page: PropTypes.bool,
    size: PropTypes.number
}