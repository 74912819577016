import React, { useRef, useState, useEffect } from 'react';

import { Card, TextInput, Textarea } from "@tremor/react";
import {  Typography, Navbar, Button } from '@material-tailwind/react';

import AlertManager from '../../general/AlertManager.jsx';

import { IoAddCircle } from "react-icons/io5";
import { FaSave } from "react-icons/fa";
import { GrPowerReset } from "react-icons/gr";

import ImageUploader from '../general/ImageUploader.jsx'
import { useOrgContext } from '../../../datahooks/admin/organisation/AdminOrgIDContext.jsx';

import useOrganisationManager from '../../../datahooks/admin/organisation/useOrganisationManager.js';

import useUser from '../../../datahooks/useUser.js';

import OrganisationKeysDialog from './OrganisationAPIKeysDialog.jsx';
import DisableOrganisationDialog from './DisableOrganisationDialog.jsx';

import LoadingIcon from '../../navigation/LoadingIcon.jsx';
import isEqual from 'lodash/isEqual';
import { normaliseData } from '../../../datahooks/metadata/DataUtils.js';

import * as yup from 'yup';

const organisationSchema = yup.object().shape({
    title: yup
      .string()
      .required('Please enter a title.')
      .max(255, 'The title should not exceed 255 characters.'),
    intro_text: yup
      .string()
      .optional()
      .max(1000, 'The intro text should not exceed 1000 characters.')
  });

function OrganisationForm() {
    const { organisationData, refetchOrganisation, changeOrganisation } = useOrgContext();
    const imageUploaderRef = useRef(null);
    const { user:userDetails } = useUser();

    const [formData, setFormData] = useState({ title: '', intro_text: '' });
    const [validationErrors, setValidationErrors] = useState({});

    const [isInitialised, setIsInitialised] = useState(false);

    const [formChanged, setFormChanged] = useState(false);
    const [imageSelected, setImageSelected] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [isNewOrg, setIsNewOrg] = useState(false);

    const organisationUpdated = (aResponse) => {
        setSuccessMessage("Organisation updated successfully."); 
        setIsInitialised(false);
        
        if (isNewOrg) {
            setIsNewOrg(false);
            changeOrganisation(aResponse.new_Id);
        } else {
            if (imageSelected) { setImageSelected(false); };
            refetchOrganisation();
        }
    }

    const organisationSaveFailed = (error) => {
        setErrorMessage(`Failed to save organisation: ${error.message || 'Unknown error'}`);
    }

    const { performAction, reset: resetSave } = useOrganisationManager(organisationUpdated, organisationSaveFailed);
    
    // Set initial data once it's available
    useEffect(() => {
        
        if (organisationData?.id) {
            setFormData({
                title: organisationData.title || '',
                intro_text: organisationData.intro_text || ''
            });
            imageUploaderRef?.current?.resetImage();
            setIsInitialised(true);
        }
    }, [organisationData]);

    useEffect(() => {
        if (isInitialised) {
            // Normalise the organisationData for comparison - set nulls to ''
            const normalisedOrganisationData = normaliseData(organisationData, ['title', 'intro_text']);
            const hasDataChanged = !isEqual(normalisedOrganisationData, formData);
            setFormChanged(hasDataChanged || imageSelected);
        }
    }, [formData, organisationData, imageSelected, isInitialised]);
    
    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        resetSave();

        try {
            // Validate the specific field against the schema
            await organisationSchema.validateAt(name, { [name]: value });
            setValidationErrors((prev) => ({ ...prev, [name]: null }));
        } catch (error) {
            setValidationErrors((prev) => ({ ...prev, [name]: error.message }));
        }
    };

    const handleImageSelected = (file) => {
        setImageSelected(true);
    };

    const handleSave = async (event) => {
        event.preventDefault();
        if (!formChanged) {
            return;
        }

        try {
            // Validate the entire form before submission
            await organisationSchema.validate(formData, { abortEarly: false });
            
            //Upload the image if one was selected
            if (imageSelected) {
                imageUploaderRef.current.uploadImage();
            } else {
                //Save the form data - no image upload required
                doSave();
            }

          } catch (error) {
            const errors = error.inner.reduce((acc, err) => {
              acc[err.path] = err.message;
              return acc;
            }, {});
            setValidationErrors(errors);
          }
    };

    const doSave = async (imagePath = null) => {
        const payload = createPayload(imagePath);
    
        if (isNewOrg) {
            handleNewOrganisation(payload);
        } else {
            handleExistingOrganisation(payload, imagePath);
        }
    };
    
    const createPayload = (imagePath) => {
        const payload = { organisation_id: organisationData.id };
    
        if (isNewOrg) {
            payload.new_title = formData.title;
            if (formData.intro_text) payload.new_intro_text = formData.intro_text;
            if (imagePath) payload.new_logo_url = imagePath;
        } else {
            const normalisedData = normaliseData(organisationData, ['title', 'intro_text']);
            if (!isEqual(normalisedData.title, formData.title)) payload.new_title = formData.title;
            if (imagePath && organisationData.logo_url !== imagePath) payload.new_logo_url = imagePath;
            if (!isEqual(normalisedData.intro_text, formData.intro_text)) payload.new_intro_text = formData.intro_text;
        }
    
        return payload;
    };
    
    const handleNewOrganisation = (payload) => {
        performAction(payload, 'PUT');
    };
    
    const handleExistingOrganisation = (payload, imagePath) => {
        if (Object.keys(payload).length > 1) {
            if (payload.new_title || payload.new_intro_text || payload.new_logo_url) {
                performAction(payload, 'POST');
            } else {
                showSuccessAndReset();
            }
        } else {
            showSuccessAndReset();
        }
    };
    
    const showSuccessAndReset = () => {
        setShowSuccessAlert(true);
        resetForm();
    };    

    const resetForm = () => {
        setFormData({
            title: organisationData.title || '',
            intro_text: organisationData.intro_text || ''
        });
        setFormChanged(false);
        imageUploaderRef?.current?.resetImage();
        setImageSelected(false);
        if (isNewOrg) { setIsNewOrg(false); }
        setValidationErrors({});
    };

    const startNewOrg = () => {
        setIsNewOrg(true);
        setFormData({
            title: '',
            intro_text: ''
        });
        imageUploaderRef.current.resetImage(true);
    }

    if (!isInitialised) {
        return <Card className='w-full' ><LoadingIcon showText /></Card>; // or a spinner/loader component
    }

    return (
        <Card className="flex flex-col w-full h-full items-center">

            <form className="flex flex-col gap-4 w-full max-w-4xl">
                <Typography variant="h5" className="text-blue-500 mt-2">
                    {`${isNewOrg ? 'Creating New ' : 'Editing '}Organisation`}
                </Typography>

                <Typography variant="h5" className="text-blue-500 mt-2">Title</Typography>
                <TextInput
                    label="Title"
                    type="text"
                    color="lightBlue"
                    size="regular"
                    placeholder="Organisation Title"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="w-full"
                    error={!!validationErrors.title}
                    errorMessage={validationErrors.title}
                />

                {(!isNewOrg && (
                    <>
                        <Typography variant="h5" className="text-blue-500 mt-2">Logo</Typography>
                        <ImageUploader 
                            imgSrc={organisationData?.logo_url || ''} 
                            ref={imageUploaderRef} 
                            onSavePath={(path) => doSave(path)}
                            imageType="org"
                            imageId={organisationData?.id}    // from the context
                            onImageSelect={handleImageSelected}
                            displayType='logo'
                        />
                    </>))}

                <Typography variant="h5" className="text-blue-500 mt-2">Intro</Typography>
                <Textarea
                    label="Intro"
                    className="material-tailwind-input w-full"
                    placeholder="Intro Text"
                    name="intro_text"
                    value={formData.intro_text}
                    onChange={handleInputChange}
                    error={!!validationErrors.intro_text}
                    errorMessage={validationErrors.intro_text}
                />

                
            </form>

            <AlertManager
                className="flex flex-col gap-4 w-full max-w-4xl"
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
            />
            
            <Navbar className='m-0 p-0 mt-5 flex items-center justify-between w-full p-4 gap-3 bg-gray-100'>
            {userDetails?.user_type === 'super_admin' ? (
                <div className='gap-3 flex items-center'>
                    <DisableOrganisationDialog 
                        orgStatus={organisationData?.org_status} 
                        organisationId={organisationData?.id}
                        onUpdate={performAction}
                        disabled={isNewOrg}
                    />

                    <OrganisationKeysDialog 
                        organisationId={organisationData?.id} 
                        disabled={isNewOrg} />

                    <Button 
                        size="sm" 
                        variant="outlined" 
                        className="flex text-blue-500 items-center gap-3"
                        onClick={() => startNewOrg()}
                        disabled={isNewOrg}
                    >
                        <IoAddCircle size={20} /> Add New
                    </Button>
                </div>
            ) : null}
                <div className='flex gap-3'>
                    <Button size="sm" variant="outlined" disabled={!formChanged} color="blue" className="flex items-center gap-3" onClick={resetForm}>
                        <GrPowerReset size={20} /> Reset
                    </Button>
                    <Button size="sm" disabled={!formChanged} color="blue" className="flex items-center gap-3" onClick={handleSave}>
                        <FaSave size={20} /> Save Changes
                    </Button>
                </div>
            </Navbar>

            
        </Card>
    );
}

export default OrganisationForm;
