import React from "react";
import { Typography } from "@material-tailwind/react";
import PropTypes from 'prop-types';

export default function SpinningTitle({theTitle = 'Dashboard'}) {

    return (
            <Typography color="gray" className="text-2xl md:text-3xl font-medium text-center w-full">
                dis<span className="rotate-q">Q</span>o {theTitle}
            </Typography>
    );
}

SpinningTitle.propTypes = {
    theTitle: PropTypes.string,
};