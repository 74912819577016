import useAuthenticatedMutation from '../useAuthenticatedMutation';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config';

// Function to perform HTTP requests
const manageOrganisation = async ({data, method}, token) => {
    const url = `${API_BASE_URL}disqoadmin/organisation`;
    try {
        const response = await axios({
            method: method,
            url: url,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-functions-key': FUNCTION_KEY
            },
        });
        return response.data;
    } catch (error) {
        console.error(`Failed to ${method.toLowerCase()} organisation:`, error);
        throw (error?.response?.data) ? error.response.data : error;
    }
};

// Hook to manage organisations
export default function useOrganisationManager(onSuccess, onError) {
    
    const projectScope = [PROJECT_SCOPE];
    const { mutate, isLoading, isError, error, data, reset: resetMutation } = useAuthenticatedMutation(manageOrganisation, projectScope, {
        onSuccess,
        onError
    });

    // Function to call the mutate function with additional parameters for different HTTP methods
    const performAction = (data, method = 'POST') => {
        // Structure the data here to include the method, the token is added by the mutation function
        mutate({
            data: data,
            method: method
        });
    };

    return {
        performAction,
        isLoading,
        isError,
        error,
        data,
        reset: resetMutation
    };
}

useOrganisationManager.propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func
}