const saucelabsConfig = {
  fieldsToShow: ['api_username', 'api_password'],
  labels: {
    api_username: "SauceLab Username",
    api_password: "SauceLab Access Key" // NOSONAR - This is the label to use for the field, not the value
  },
  helpText: {
    api_username: "Your Saucelabs username.",
    api_password: "Your Saucelabs access key."  // NOSONAR - This is the label to use for the field, not the value
  }
};

export const testTypesConfig = {
  "-1": { // Default state when no test type is selected
    fieldsToShow: [],
    labels: {},
    helpText: {}
  },
  "1": { // SonarQube
    fieldsToShow: ['api_key', 'api_password'],
    labels: {
      api_key: "API Key",
      api_password: "Component Key" // NOSONAR - This is the label to use for the field, not the value
    },
    helpText: {
      api_key: "Your SonarQube API key.",
      api_password: "Component key as required by SonarQube." // NOSONAR - This is the label to use for the field, not the value
    }
  },
  "2": { // Saucelabs API testing
    ...saucelabsConfig
  },
  "3": { // Saucelabs Functional UI testing
    ...saucelabsConfig
  },
  "4": { // JMeter Performance testing
    fieldsToShow: [],
    labels: {},
    helpText: {}
  },
  "5": { // Saucelabs Accessibility testing
    ...saucelabsConfig
  }
};