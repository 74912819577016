// OrganisationKeysDialog.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from '@material-tailwind/react';

import { FaKey } from "react-icons/fa";
import { IoAddCircle } from "react-icons/io5";

import useProjectTestTypes from '../../../datahooks/admin/project/useProjectTestTypes.js';
import useProjectTestTypeManager from '../../../datahooks/admin/project/useProjectTestTypesManager.js';

import LoadingIcon from '../../navigation/LoadingIcon.jsx';
import AlertManager from '../../general/AlertManager.jsx';
import ProjectTestTypesEditForm from './ProjectTestTypesEditForm.jsx';
import ProjectTestTypesTable from './ProjectTestTypesTable.jsx';

const ProjectTestTypesDialog = ({projectId}) => {
    const [ isOpen, setIsOpen] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [ addingNewTestType, setAddingNewTestType ] = useState(false);
    const [ editingTestType, setEditingTestType ] = useState(-1);

    const { projectTestTypesData, projectTestTypesLoading, projectTestTypesError, refetchProjectTestTypes } = useProjectTestTypes(projectId);

    const projectTestTypeUpdated = (aResponse) => {
        refetchProjectTestTypes();
        setSuccessMessage('Test type updated successfully.');
    }

    const projectTestTypeSaveFailed = (error) => {
        setErrorMessage('Failed to save test type: ' + error.message || 'Unknown error');
    }

    const { performAction } = useProjectTestTypeManager(projectTestTypeUpdated, projectTestTypeSaveFailed);
    
    const handleToggle = () => setIsOpen(!isOpen);

    const doDelete = (testTypeId) => {
        
        const payload = {
            api_id: testTypeId,
        };
        
        performAction(payload, 'DELETE');   
    }

    const handleUpdatedTestType = () => {
        if (addingNewTestType) {
            setAddingNewTestType(false);
            setSuccessMessage('New test type added successfully.');
        }
        refetchProjectTestTypes();
    }

    if (projectTestTypesLoading) {
        return <LoadingIcon showText />
    }

    if (projectTestTypesError) {
        return <div>Error: {projectTestTypesError}</div>
    }

    return (
        <>
            <Button size="sm" variant="outlined" className="flex text-blue-500 items-center gap-3" onClick={handleToggle}>
                <FaKey size={20} /> Test Types
            </Button>

            <Dialog open={isOpen} size="xl" handler={handleToggle} dismissible={false}>
                    {addingNewTestType && <ProjectTestTypesEditForm 
                                            addingTestType={addingNewTestType} 
                                            onCancel={() => { setAddingNewTestType(false)} } 
                                            onUpdatedTestType={handleUpdatedTestType}
                                            projectId={projectId}
                                        />}

                    {editingTestType !== -1 && <ProjectTestTypesEditForm 
                                                acdId={editingTestType} 
                                                onCancel={() => { setEditingTestType(-1)} }
                                                onUpdatedTestType={handleUpdatedTestType}
                                            />}

                    {!addingNewTestType && editingTestType === -1 && (
                        <ProjectTestTypesTable
                            projectTestTypesData={projectTestTypesData} 
                            onEditTestType={(testTypeId) => setEditingTestType(testTypeId)}
                            onDeleteTestType={(api_id) => doDelete(api_id)}
                        />
                    )}

                    <AlertManager
                        className="m-2"
                        successMessage={successMessage} 
                        setSuccessMessage={setSuccessMessage} 
                        errorMessage={errorMessage} 
                        setErrorMessage={setErrorMessage}   
                    />
                
                <Dialog.Footer >
                    <div className="flex justify-between w-full">
                        <div className='flex gap-3'>
                            <Button 
                                variant='outlined' 
                                color='blue' 
                                className="flex items-center gap-3"
                                onClick={() => setAddingNewTestType(true)}
                                disabled={addingNewTestType || editingTestType !== -1}
                            >
                                <><IoAddCircle size={20} /> Add</>
                            </Button>
                        </div>

                        <div>
                        <Button color="pink" onClick={handleToggle} disabled={addingNewTestType || editingTestType !== -1}>
                            Close
                        </Button>
                        </div>
                    </div>
                    </Dialog.Footer>
            </Dialog>
        </>
    );
}
ProjectTestTypesDialog.propTypes = {
    projectId: PropTypes.number,
};

export default ProjectTestTypesDialog;