import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../config';
import { getTimeDifference } from './metadata/DataUtils';

export default function useReleaseOverview(releaseId) {
  const fetchReleaseOverview = async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}release_overview`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        },
        params: {
          release_id: releaseId,
        }
      });

      const dataWithRuntime = Object.entries(response.data).reduce((acc, [key, value]) => {
        const runtime = getTimeDifference(value.creation, value.last_updated);
        acc[key] = { ...value, runtime };
        return acc;
      }, {});

      return dataWithRuntime;
    } catch (error) {
      console.error('Failed to load release overview in useReleaseOverview', error); // Error during fetch
      throw error;
    }
  };

  const projectScope = [PROJECT_SCOPE];
  const queryKey = ['release_overview', releaseId];

  const { data: releaseOverview = [], isLoading, refetch, error } = useAuthenticatedQuery(queryKey, fetchReleaseOverview, projectScope, {
      staleTime: 2 * 60 * 1000, // The data will be considered stale after 2 minutes
  });


  // Additional logs to monitor the state changes
  if (error) {
    console.error('Error fetching release overview:', error);
  }

  return { releaseOverview, isLoading, refetch };
}