import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-tailwind/react';
import IssueStatus from '../../general/IssueStatus';
import PriorityChip from '../shared/PriorityChip';

const FunctionalUIItemHeader = ({ test }) => {
  return (
    <div data-testid="fui-item-header" className="grid items-center w-full divide-x divide-gray-400" style={{ gridTemplateColumns: 'auto 1fr auto auto auto', boxSizing: 'border-box' }}>
      <div className="flex justify-end pl-3 pr-3" title={`Result: ${test.status}`}>
        <IssueStatus status={test.status} size={35} />
      </div>
      <Typography variant='lead' className="pl-5 pr-3 flex-grow" title={`Test: ${test.name}`}>
        {test.name}
      </Typography>
      <div className="pl-3 mr-2 " style={{ boxSizing: 'border-box' }} title={`Area: ${test.area}`}>
        { test.area || null }
      </div>
      <div className="pl-3 mr-2 " style={{ boxSizing: 'border-box' }} title={`Priority: ${test.priority}`}>
        { test.priority && <PriorityChip priority={test.priority} /> }
      </div>
    </div>
  );
};

FunctionalUIItemHeader.propTypes = {
  test: PropTypes.shape({
    status: PropTypes.string,
    name: PropTypes.string,
    area: PropTypes.string,
    priority: PropTypes.string,
  }).isRequired,
};

export default FunctionalUIItemHeader;