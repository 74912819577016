import React from 'react';
import PropTypes from 'prop-types';
import { Card, Title, Metric } from "@tremor/react";
import RatingDonut from '../shared/RatingDonut';

const StaticCodeMetric = ({ data }) => {

  const renderMetricValue = () => {
    switch (data.type) {
      case 'INT':
        return <Metric>{data.value}</Metric>;
      case 'RATING':
        // Here you can return the RatingDonut or another component designed for ratings
        return <RatingDonut className="w-1/2" rating={Number(data.value)}  />;
      // Handle other types as necessary
      case 'WORK_DUR':
        return <Metric>{(data.value / 60).toFixed(2)} hours</Metric>;
      default:
        return <Metric>{data.value}</Metric>;
    }
  };
  
  let decorationColor;

  if (data.type === 'RATING') {
      decorationColor = data.bestValue === data.value ? "green" : "pink";
  } else {
      decorationColor = (data.bestValue === "1" || data.bestValue === "1.0") ? "green" : "pink";
  }

  return (
    <Card className="flex w-full h-full flex-col" decoration="top" decorationColor={decorationColor}>
      <Title>{data.name}</Title>
      {renderMetricValue()}
    </Card>
  );
};

StaticCodeMetric.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bestValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
};

export default StaticCodeMetric;
