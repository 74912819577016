import React from 'react';
import { Navbar, Typography, Chip } from "@material-tailwind/react";

import { useOrgContext } from '../../../datahooks/admin/organisation/AdminOrgIDContext.jsx';
import useUser from '../../../datahooks/useUser';
import LoadingIcon from '../../navigation/LoadingIcon.jsx';

import SelectOrganisationDialog from './SelectOrganisation.jsx';

function OrganisationMenuBar() {
    const { organisationData, loadingOrganisation, organisationError, changeOrganisation } = useOrgContext();
    const { user:userDetails } = useUser();

    if (loadingOrganisation) return <div><LoadingIcon showText /></div>;
    if (organisationError) return <div>Error: {organisationError}</div>;

    const handleOrganisationChange = (newOrganisation) => {
        changeOrganisation(newOrganisation.id);
    }

    return (
        <Navbar className="flex items-center justify-between top-0 relative w-full gap-3 max-w-full rounded-none px-2 py-2 my-1 lg:px-2 lg:py-1 border-b border-default">
            <div className="flex items-center flex-grow">
                <Typography variant="h4" color="blue">{organisationData?.title || "Select Organisation"}</Typography>                
            </div>
            {organisationData.org_status == 'inactive' && <Chip color="pink" size="lg" className="ml-2" value={organisationData?.org_status || ""} /> }
            {userDetails?.user_type === 'super_admin' && <SelectOrganisationDialog currentOrgId={organisationData?.id} onChangeOrganisation={handleOrganisationChange} /> }
        </Navbar>
    );
}

export default OrganisationMenuBar;