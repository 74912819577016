import React from 'react';
import PropTypes from 'prop-types';
import { HiCheckCircle, HiXCircle } from 'react-icons/hi';

const IssueStatus = ({ status = 'failed', size = 35 }) => {
    // Convert status to camel case for tooltip
    const camelCaseStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

    if (status === 'passed' || status === 'success') {
        return (
            <div title={camelCaseStatus}>
                <HiCheckCircle className="text-green-500" size={size} />
            </div>
        );
    } else {
        return (
            <div title={camelCaseStatus}>
                <HiXCircle className="text-pink-500" size={size} />
            </div>
        );
    }
};

IssueStatus.propTypes = {
    status: PropTypes.string,
    size: PropTypes.number
};

export default IssueStatus;