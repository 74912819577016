import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_ADMIN_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import useUser from '../../useUser.js';
import logError from '../../metadata/ErrorLogging.js';

export default function useUserProjectAccess(userId = null) {
  const { user:userDetails } = useUser();

  const fetchUserProjectAccess = async (token) => {
    const url = `${API_ADMIN_URL}user_project_perms?target_user_id=${userId}`;
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        }
      });
      return response.data;
    } catch (error) {
      const errorMessage = `Failed to get user project access list: ${error?.message}`;
      const responseBody = (error?.response?.data ? error.response.data : "");
      
      logError(errorMessage, error, { "URL": url, "responseBody": responseBody, "userId": userId, "errorMessage": errorMessage});

      throw error;
    }
  };
  
  const { data: userProjectAccessData = [], isLoading, refetch, error } = useAuthenticatedQuery(`user_project_access_${userDetails?.id}_${userId}`, fetchUserProjectAccess, [PROJECT_SCOPE], {
    staleTime: 600000, // 10 minutes in milliseconds - could be longer but another user may change the data if we cache for too long
    enabled: !!userId && !!userDetails?.id
  });

  return { userProjectAccessData, loadingUserProjectAccess: isLoading, refetchUserProjectAccess: refetch, userProjectAccessError: error };
}

useUserProjectAccess.propTypes = {
  userId: PropTypes.number
}  