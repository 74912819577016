import React from 'react';
import PropTypes from 'prop-types';
import { Card, Drawer } from "@material-tailwind/react";
import MenuLinks from './MenuLinks';
import MenuLinksAdmin from './MenuLinksAdmin';

import { useAuth } from './Authentication';
import useUser from '../../datahooks/useUser';


/**
 * SideBar component that displays a sidebar with dynamic project items
 * @param {Object} props - The properties passed to the component
 * @param {Function} props.handleClick - Function to handle click events on sidebar items
 * @param {Boolean} props.drawerOpen - Boolean to control the opening and closing of the Drawer from outside
 * @returns {JSX.Element} The SideBar component
 */
export default function SideBar({ handleClick, drawerOpen, isAdmin = false }) {
  const { user:userDetails } = useUser();
  const { signOut } = useAuth(); // Access signIn via useAuth from Authentication.jsx
  const doLogout = () => {
    signOut();
  };

  return (
    <div className='border-r border-default'>
      <Drawer open={drawerOpen} onClose={() => handleClick(false)} className="p-4">
        {isAdmin && (userDetails?.user_type == 'super_admin' || userDetails?.user_type == 'admin') ? <MenuLinksAdmin doLogout={doLogout} /> : <MenuLinks doLogout={doLogout} />}
      </Drawer>
      <Card className="hidden lg:block h-[calc(100vh-2rem)] w-full max-w-[15rem] p-4 shadow-xl shadow-blue-gray-900/5">
      {isAdmin && (userDetails?.user_type == 'super_admin' || userDetails?.user_type == 'admin') ? <MenuLinksAdmin doLogout={doLogout} /> : <MenuLinks doLogout={doLogout} />}
      </Card>
    </div>
  );
}

SideBar.propTypes = {
  handleClick: PropTypes.func,
  drawerOpen: PropTypes.bool,
  isAdmin: PropTypes.bool,
};