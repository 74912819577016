import { useMutation } from 'react-query';
import PropTypes from 'prop-types';
import { useAuth } from '../../components/navigation/Authentication.jsx';

export default function useAuthenticatedMutation(mutationFn, scopes = ["openid"], config = {}) {
    const { acquireTokenSilent, isInitialised } = useAuth();

    const enhancedMutationFn = async (data) => {
        if (!isInitialised) {
            throw new Error('MSAL instance not initialised');
        }

        try {
            const tokenResponse = await acquireTokenSilent(scopes);
            return await mutationFn(data, tokenResponse);
        } catch (error) {
            throw error;  // Propagate the error up to react-query for handling retries or failures
        }
    };

    //Error callbacks not implemented here to ensure they are handled by the caller
    //If an error callback is added here, it will override the caller's error callback
    return useMutation(enhancedMutationFn, {
        ...config
    });
}

useAuthenticatedMutation.propTypes = {
    mutationFn: PropTypes.func,
    scopes: PropTypes.arrayOf(PropTypes.string),
    config: PropTypes.object
}