// OrganisationKeysDialog.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from '@material-tailwind/react';

import useUserProjectAccess from '../../../datahooks/admin/user/useUserProjectAccess.js';
import useUserProjectAccessManager from '../../../datahooks/admin/user/useUserProjectAccessManager.js';

import { FaKey } from 'react-icons/fa';
import { IoAddCircle } from 'react-icons/io5';

import LoadingIcon from '../../navigation/LoadingIcon.jsx';
import AlertManager from '../../general/AlertManager.jsx';

import UserProjectsDialogTable from './UserProjectsDialogTable.jsx';
import UserProjectAddForm from './UserProjectAddForm.jsx';

import { ErrorCard, CARD_TYPES } from '../../cards/ErrorCard';

const UserProjectsDialog = ({userId, updateUserTable}) => {
    const [ isOpen, setIsOpen] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [ addingNewProject, setAddingNewProject ] = useState(false);

    const { userProjectAccessData, loadingUserProjectAccess, refetchUserProjectAccess, userProjectAccessError } = useUserProjectAccess(userId);
    
    const userProjectAccessUpdated = (aResponse) => {
        refetchUserProjectAccess();
        setSuccessMessage('Project access granted successfully.');
        if (updateUserTable) {
            updateUserTable();
        }
    }

    const userProjectAccessSaveFailed = (error) => {
        setErrorMessage('Failed to grant user access: ' + error.message || 'Unknown error');
    }

    const { performAction } = useUserProjectAccessManager(userProjectAccessUpdated, userProjectAccessSaveFailed);

    const handleToggle = () => setIsOpen(!isOpen);

    const doSave = (projectId) => {
        
        // Ensure formData contains the required fields
        const payload = {
            new_project_id: projectId,
            target_user_id:userId
        };
    
        performAction(payload, 'PUT'); // Perform the PUT operation
        setAddingNewProject(false); // Resetting the 'adding' state
    };

    const doDelete = (projectId, newStatus) => {
        
        if (newStatus == 'inactive') {
            // Delete the project access
            const payload = {
                del_project_id: projectId,
                del_user_id: userId
            };
            
            performAction(payload, 'DELETE');   
        }
    }

    if (loadingUserProjectAccess) {
        return (
            <div className="flex flex-col justify-center items-center p-4">
                <LoadingIcon showText />
            </div>
        );
    }

    if (userProjectAccessError) {
        return (
            <div className="flex flex-col justify-center items-center p-4">
                <ErrorCard cardType={CARD_TYPES.ERROR}>
                    <div>Error: {projectTestTypesError}</div>
                </ErrorCard>
            </div>
        );
    }

    const accessibleProjects = userProjectAccessData.filter(p => p.has_access === 1);
    const availableProjects = userProjectAccessData.filter(p => p.has_access === 0);
    
    return (
        <>
            <Button size="sm" variant="outlined" className="flex text-blue-500 items-center gap-3" onClick={handleToggle}>
                <FaKey size={20} /> Projects
            </Button>

            <Dialog 
                open={isOpen} 
                size="xl" 
                handler={handleToggle} 
                dismissible={false}
                className="p-4"
            >
                    {addingNewProject && <UserProjectAddForm 
                                            availableProjects={availableProjects}    
                                            onCancel={() => setAddingNewProject(false)}
                                            onUpdateProjectAccess={doSave}   
                                        />}

                    {!addingNewProject && (
                        <UserProjectsDialogTable
                            userProjectAccessData={accessibleProjects}
                            onToggleAccess={(project_id, newStatus) => doDelete(project_id, newStatus )}
                        />
                    )}

                    <AlertManager
                        successMessage={successMessage} 
                        setSuccessMessage={setSuccessMessage} 
                        errorMessage={errorMessage} 
                        setErrorMessage={setErrorMessage}   
                    />
                
                <Dialog.Footer >
                    <div className="flex justify-between w-full">
                        <div className='flex gap-3'>
                            <Button 
                                variant='outlined' 
                                color='blue' 
                                className="flex items-center gap-3"
                                onClick={() => setAddingNewProject(true)}
                                disabled={addingNewProject || availableProjects.length === 0}
                            >
                                <><IoAddCircle size={20} /> Add</>
                            </Button>
                        </div>

                        <div>
                        <Button color="pink" onClick={handleToggle} disabled={addingNewProject}>
                            Close
                        </Button>
                        </div>
                    </div>
                    </Dialog.Footer>
            </Dialog>
        </>
    );
}

UserProjectsDialog.propTypes = {
    userId: PropTypes.number,
    updateUserTable: PropTypes.func,
};

export default UserProjectsDialog;