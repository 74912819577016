import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-tailwind/react';
import { IMAGES_URL } from '../../config';

/**
 * Renders an avatar logo component.
 *
 * @component
 * @param {string} props.src - The source URL of the image, relative to teh image base URL.
 * @param {string} props.alt - The alternative/tooltip text for the image.
 * @param {string} [props.size='md'] - The size of the avatar logo. Possible values: 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'.
 * @param {number} [props.initialsCount=1] - The number of initials to display in the placeholder, from the alt text.
 * @param {string} [props.displayType='avatar'] - The type of display. Possible values: 'avatar', 'logo'.
 * @param {boolean} [props.withBorder=false] - Whether to display a border around the avatar.
 * @returns {JSX.Element|null} The rendered AvatarLogo component.
 */
const AvatarLogo = ({ src, alt, size = 'md', initialsCount = 1, displayType = 'avatar', withBorder = false }) => {
  const [hasError, setHasError] = useState(false);

  // Check if the source URL is a local blob URL
  const isLocalSrc = src?.startsWith('/') || src?.startsWith('blob:') || !src;
  const formattedSrc = src?.startsWith('/') ? src.slice(1) : src;
  const imageUrl = isLocalSrc ? src : `${IMAGES_URL}${formattedSrc}`;

  // Check if a placeholder should be displayed
  const shouldDisplayPlaceholder = hasError || !src;
  if (shouldDisplayPlaceholder && !alt) {
    return null;
  }

  // Function to extract initials from text
  const extractInitials = (text, count) => {
    return text.split(' ').slice(0, count).map(word => word[0].toUpperCase()).join('');
  };

  // Set the placeholder text
  const placeholder = alt ? extractInitials(alt, initialsCount) : 'X';

  // CSS classes for different avatar sizes
  const avatarSizeClasses = {
    xs: 'w-6 h-6 text-xs',
    sm: 'w-8 h-8 text-sm',
    md: 'w-10 h-10 text-base',
    lg: 'w-12 h-12 text-lg',
    xl: 'w-14 h-14 text-xl',
    xxl: 'w-16 h-16 text-2xl',
  };

  // CSS classes for different image sizes
  const imageSizeClasses = {
    xs: 'h-8 text-xs',
    sm: 'h-12 text-sm',
    md: 'h-14 text-base',
    lg: 'h-16 text-lg',
    xl: 'h-20 text-xl',
    xxl: 'h-24 text-2xl',
  };

  // Get the CSS class for the avatar size
  const avatarAppliedSizeClass = avatarSizeClasses[size] || avatarSizeClasses.md;
  const appliedSizeClass = imageSizeClasses[size] || imageSizeClasses.md;
  
  // Render the image based on the display type
  const renderImage = () => {
    if (displayType === 'avatar') {
      return (
        <Avatar
          src={imageUrl}
          alt={alt}
          size={size}
          onError={() => setHasError(true)}
          withBorder={withBorder}
          color='gray'
        />
      );
    } else {
      return (
        <img
          src={imageUrl}
          alt={alt}
          className={`object-contain ${appliedSizeClass}`}
        />
      );
    }
  };

  // Render the component
  return (
    shouldDisplayPlaceholder ? (
      <div className={`flex flex-shrink-0 justify-center items-center rounded-full bg-gray-500 text-white ${avatarAppliedSizeClass}`}>
        {placeholder}
      </div>
    ) : (
      renderImage()
    )
  );
};

// Prop types for the AvatarLogo component
AvatarLogo.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  initialsCount: PropTypes.number,
  displayType: PropTypes.oneOf(['avatar', 'logo']),
  withBorder: PropTypes.bool,
};

export default AvatarLogo;