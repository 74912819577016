import React from 'react';
import PropTypes from 'prop-types';
import {  Typography } from '@material-tailwind/react';

import { ErrorCard, CARD_TYPES } from '../../cards/ErrorCard';

import DisableDialog from '../general/DisableDialog';
import AvatarLogo from '../../general/AvatarLogo';

const UserProjectsDialogTable = ({ userProjectAccessData = [], onToggleAccess = () => {}}) => {

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold">Project Access</h3>
            </div>


        {userProjectAccessData.length === 0 ? (
            <div className="flex flex-col justify-center items-center p-4">
                <ErrorCard cardType={CARD_TYPES.EMPTY} />
                <Typography variant='h6'>The user does not have access to any projects.</Typography>
            </div>
        ) : (
            <div className="flex flex-col space-y-2">

                {userProjectAccessData?.map((projectAccess) => (
                    <div className="flex justify-between p-2 gap-2" key={`${projectAccess.project_id}_${projectAccess.user_id}`}>
                        <div className="w-1/12">
                            <AvatarLogo
                                src={projectAccess.logo_location}
                                alt={`${projectAccess.short_name} project logo`}
                                size='sm'
                                initialsCount={2}
                                displayType='logo'
                            />
                        </div>
                        <div className="w-10/12 flex items-center">
                            <Typography variant='h6'>{projectAccess.title}</Typography>
                        </div>
                        
                        <div className="w-1/12">
                            <DisableDialog 
                                showText={false} 
                                theId={projectAccess.project_id} 
                                theStatus='active' 
                                theType='Project Access' 
                                onConfirm={onToggleAccess} 
                            />
                        </div>
                    </div>
                ))
                }
            </div>
        )}
        </div>
    );
};
UserProjectsDialogTable.propTypes = {
    userProjectAccessData: PropTypes.array,
    onToggleAccess: PropTypes.func,
};

export default UserProjectsDialogTable;
