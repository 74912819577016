import React from 'react';
import AdminLayout from '../layouts/AdminLayout.jsx';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import LoadingIcon from '../components/navigation/LoadingIcon.jsx';
import useSwaggerSpec from '../datahooks/admin/useSwaggerSpec.js';
import { API_BASE_URL } from '../config.js';

export default function ApiDashboard() {
    const { swaggerSpec, isLoading, error } = useSwaggerSpec(API_BASE_URL);

    if (error) {
        console.error('Error while fetching Swagger spec:', error);
        return <p>Error loading API documentation.</p>;
    }

    return (
        <AdminLayout superAdminOnly>
            {isLoading ? (
                <LoadingIcon showText />
            ) : (
                <SwaggerUI spec={swaggerSpec} />
            )}
        </AdminLayout>
    );
}
