import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import Project from './pages/Project.jsx';
import Home from './pages/Home.jsx'
import Login from './pages/Login.jsx';

import AdminHome from './pages/admin/AdminHome.jsx';

import { AuthProvider } from './components/navigation/Authentication.jsx';
import Faq from './pages/Faq.jsx';
import UserProfile from './pages/UserProfile.jsx';
import Api from './pages/Api.jsx';
import ApiDashboard from './pages/ApiDashboard.jsx';

import AdminOrganisation from './pages/admin/AdminOrganisation.jsx';
import AdminTestTypes from './pages/admin/AdminTestTypes.jsx';
import AdminUsers from './pages/admin/AdminUsers.jsx';
import AdminProjects from './pages/admin/AdminProjects.jsx';
import AdminAuditLog from './pages/admin/AdminAuditLog.jsx';

import { OrganisationProvider } from './datahooks/admin/organisation/AdminOrgIDContext.jsx';


const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <OrganisationProvider>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/home" element={<Home />} />
              <Route path="/projects/:projectId" element={<Project />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/user" element={<UserProfile />} />
              <Route path="/api" element={<Api />} />
              <Route path="/apidashboard" element={<ApiDashboard />} />
              <Route path="/admin" element={<AdminHome />} />
              <Route path="/admin_organisation" element={<AdminOrganisation />} />
              <Route path="/admin_testtypes" element={<AdminTestTypes />} />
              <Route path="/admin_users" element={<AdminUsers />} />
              <Route path="/admin_projects" element={<AdminProjects />} />
              <Route path="/admin_auditlog" element={<AdminAuditLog />} />
            </Routes>
          </OrganisationProvider>
        </AuthProvider>
      </Router>
  </QueryClientProvider>
  )
}

export default App
