import { Card, Grid } from "@tremor/react";
import PropTypes from 'prop-types';
import LoadingIcon from "../../navigation/LoadingIcon";
import StaticCodeMetric from "./StaticCodeMetric";

export default function StaticCodeMetrics({ metricData }) {
    
  return (
    <>
     
      <Grid numItems={2} numItemsMd={3} numItemsLg={3} className="gap-6 mb-5 w-full justify-items-center">
            {metricData?.metrics?.measures ? (
                  metricData.metrics.measures.filter(metric => metric.type !== 'RATING').map(metric => (
                    <StaticCodeMetric key={metric.metric} data={metric} />
                  ))
            ) : (
              <Card className="flex flex-col justify-center items-center h-full">
                <LoadingIcon showText />
              </Card>
            )}
      </Grid>
      <Grid numItems={2} className="gap-6 mb-5 w-full">
          {metricData?.metrics?.measures ? (
              metricData.metrics.measures.filter(metric => metric.type === 'RATING').map(metric => (
                <StaticCodeMetric key={metric.metric} data={metric} />
              ))
          ) : (
            <Card className="flex flex-col justify-center items-center h-full">
              <LoadingIcon showText />
            </Card>
          )}
      </Grid>
    </>
  );
}

StaticCodeMetrics.propTypes = {
  metricData: PropTypes.object
}