import axios from 'axios';
import { API_ADMIN_URL, FUNCTION_KEY } from '../config';

import logError from './metadata/ErrorLogging';

const useUpdateLastLogin = (onSuccess, onError) => {

    const updateLastLogin = async (userId, token) => {
        const url = `${API_ADMIN_URL}user`;
        if (!userId || !token) return;
        
        const data = {
            edit_user_id: userId,
            new_login: 1,
        };
        try {
            const response = await axios.post(url, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'x-functions-key': FUNCTION_KEY,
                },
            });

            if (onSuccess) {
                onSuccess(response.data);
            }
        } catch (error) {
            const errorMessage = `Failed to update last login: ${error.message}`;
            const responseBody = error.response ? error.response.data.errorResponse : null;

            logError(errorMessage, error, { "URL": url, "Data": data, "responseBody": responseBody });
            if (onError) {
                onError(error);
            }
        }
    };

    return updateLastLogin;
};

export default useUpdateLastLogin;
