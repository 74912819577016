import React from 'react';

import AdminLayoutOrganisation from '../../layouts/AdminLayoutOrganisation.jsx';

import EditOrganisation from '../../components/admin/organisation/EditOrganisation.jsx';

export default function AdminOrganisation() {
  
  return (
    <AdminLayoutOrganisation>
      <div className="mt-2 md:mt-1 p-0 md:p-6 bg-gray-50 rounded-lg border ">
          <EditOrganisation />
      </div>
    </AdminLayoutOrganisation>
  );
}