import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, IconButton, Typography } from '@material-tailwind/react';
import { FaEdit } from 'react-icons/fa';
import { ErrorCard, CARD_TYPES } from '../../cards/ErrorCard';
import AvatarLogo from '../../general/AvatarLogo';
import DisableDialog from '../general/DisableDialog';

const UsersTable = ({ usersData = [], onDeleteUser, onEditUser }) => {
  const [showActiveUsers, setShowActiveUsers] = useState(true);

  const handleToggleUsers = () => {
    setShowActiveUsers(!showActiveUsers);
  };

  const filteredUsers = usersData?.filter((user) => 
    showActiveUsers ? user.user_status === 'active' : user.user_status !== 'active'
  );

  return (
    <div className="w-full">
        <div className="flex justify-between items-center mb-4">
            <Typography variant='h4'>Users</Typography>
            <Switch
                color="blue"
                className='ml-auto'
                label={`Show ${showActiveUsers ? 'Active' : 'Inactive'} Users`}
                checked={showActiveUsers}
                onChange={handleToggleUsers}
            />
        </div>

      {filteredUsers.length === 0 ? (
        <div className="flex flex-col justify-center items-center p-4">
            <ErrorCard cardType={CARD_TYPES.EMPTY} />
            <Typography variant='h6'>No {showActiveUsers ? 'active' : 'inactive'} users found. 
              {showActiveUsers && ' Add a new user to get started.'}
            </Typography>
        </div>
      ) : (
        <div className="flex flex-col space-y-2">
          <div className="flex justify-between items-center p-2" >
            <div className="w-1/12"></div>
            <Typography variant='h5' className="w-1/3">Name</Typography>
            <Typography variant='h5' className="w-1/3">Email</Typography>
            <Typography variant='h5'  className="w-1/12">Projects</Typography>
            <div className="w-1/6"></div>
          </div>
          {filteredUsers.map((user) => (
            <div className="flex justify-between items-center p-2" key={user.id}>
                <div className="w-1/12">
                    <AvatarLogo src={user.image_url} alt={`${user.display_name} profile image`} size='sm' initialsCount={2} displayType='avatar' />
                </div>
              
                <div className="w-1/3">{user.display_name}</div>

                <div className="w-1/3 break-words">{user.email_address}</div>

                <div className="w-1/12">{user.project_count}</div>
              
                <div className="w-1/6 flex justify-end gap-2">

                    {user.user_status == 'active' && <IconButton 
                        color='blue' 
                        variant='text'
                        alt={`Edit user?`}
                        onClick={() => onEditUser(user.id)}
                    >
                        <FaEdit size={22} />
                    </IconButton>
                    }
                    <DisableDialog 
                        theId={user.id}
                        theStatus={user.user_status}
                        theType='User'
                        showText={false}
                        onConfirm={onDeleteUser}
                    />
                </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
UsersTable.propTypes = {
  usersData: PropTypes.array,
  onDeleteUser: PropTypes.func,
  onEditUser: PropTypes.func
};
export default UsersTable;
