import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import { convertDateToLocal } from '../../metadata/DataUtils.js';
import useUser from '../../useUser.js';

export default function useProject(projectId = null) {
  const { user: userDetails } = useUser();

  const fetchProject = async (token) => {
    if (!projectId || projectId === -1) {
      return null; // Skip fetching if projectId is invalid or not set
    }

    try {
      const response = await axios.get(`${API_BASE_URL}disqoadmin/project?project_id=${projectId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        }
      });

      const project = response.data;

      if (project.released_into_test) {
        project.released_into_test = convertDateToLocal(project.released_into_test, false);
      }

      if (project.target_to_live) {
        project.target_to_live = convertDateToLocal(project.target_to_live, false);
      }
      
      return project;

    } catch (error) {
      console.error('Failed to load project in useProject', error); // Error during fetch
      throw error;
    }
  };

  const { data: projectData = null, isLoading, refetch, error } = useAuthenticatedQuery(
    `project_${userDetails?.id}_${projectId}`,
    fetchProject,
    [PROJECT_SCOPE],
    {
      staleTime: 60000, // Data is considered fresh for 1 minute
      enabled: !!projectId && projectId !== -1 // Disable query if projectId is invalid or not set
    }
  );

  if (error) {
    console.error('Error fetching project:', error);
  }
  
  return { projectData, loadingProject: isLoading, refetchProject: refetch, projectError: error };
}

useProject.propTypes = {
  projectId: PropTypes.number
}