import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../config';
import { convertDateToLocal } from './metadata/DataUtils';
import { useOrgContext } from './admin/organisation/AdminOrgIDContext';
import useUser from './useUser';

export default function useProjects() {
  const { organisationData, userOrganisation } = useOrgContext();
  const { user } = useUser();

  // Determine the correct orgId to use for both query key and API call
  let orgIdToUse = userOrganisation?.id || 'own_org';

  // If the user is a super admin and the selected organisation is different from the user's own organisation
  if (user?.user_type === 'super_admin' && organisationData?.id !== userOrganisation?.id) {
    orgIdToUse = organisationData?.id;
  }

  const fetchProjects = async (token) => {
    try {
      
      const response = await axios.get(`${API_BASE_URL}user_projects`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        },
        params: orgIdToUse && orgIdToUse !== 'own_org' ? { org_id: orgIdToUse } : {}
      });

      // Sort releases for each project by descending creation date
      const sortedProjects = response.data.map(project => {
        if (project.releases && project.releases.length > 0) {
          project.releases.sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date));
        }

        // Convert the dates from MySQL to user's local format
        if (project.released_into_test) {
          project.released_into_test = convertDateToLocal(project.released_into_test, true);
        }

        if (project.target_to_live) {
          project.target_to_live = convertDateToLocal(project.target_to_live, true);
        }
       
        return project;
      });

      return sortedProjects;
    } catch (error) {
      console.error('Failed to load projects in useProjects', error);
      throw error;
    }
  };

  const projectScope = [PROJECT_SCOPE];

  // Use the same orgIdToUse for both the query key and the API call
  const queryKey = ['projects', orgIdToUse];

  const { data: projects = [], isLoading, refetch, error } = useAuthenticatedQuery(queryKey, fetchProjects, projectScope, {
    enabled: !!organisationData || !!userOrganisation,
    staleTime: 2 * 60 * 1000,
  });

  return { projects, isLoading, refetch, error };
}