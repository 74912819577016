import React from 'react';
import PropTypes from 'prop-types';
import useReleaseOverview from '../../../datahooks/useReleaseOverview';
import OverviewFirstRow from './OverviewFirstRow';
import LoadingIcon from '../../navigation/LoadingIcon';

const OverviewTabPanel = ({ releaseId, ...rest }) => {

    const { releaseOverview, isLoading } = useReleaseOverview(releaseId);
    
    return (
        isLoading ? <LoadingIcon showText /> : (
          <OverviewFirstRow 
            {...rest}
            releaseId={releaseId}
            releaseData={releaseOverview} 
          />
        )
      );
};

OverviewTabPanel.propTypes = {
  releaseId: PropTypes.string
};

export default OverviewTabPanel;