import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogBody, DialogFooter, Button, Typography, List, ListItem, ListItemPrefix, ListItemSuffix, Switch } from '@material-tailwind/react';
import useOrganisations from '../../../datahooks/admin/organisation/useOrganisations';
import AvatarLogo from '../../general/AvatarLogo';
import { MdSwapHorizontalCircle } from 'react-icons/md';
import { FaDotCircle } from 'react-icons/fa';
import LoadingIcon from '../../navigation/LoadingIcon';

const SelectOrganisationDialog = ({ currentOrgId, onChangeOrganisation }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showActive, setShowActive] = useState(true);
    const { organisationsData = [], organisationsLoading, refetchOrganisations, organisationsError } = useOrganisations();

    const handleToggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (isOpen) {
            refetchOrganisations();
        }
    }, [isOpen, refetchOrganisations]);

    const changeOrganisation = (org) => {
        onChangeOrganisation(org);
        setIsOpen(false);
    };
    
    const filteredOrganisations = organisationsData?.filter(org => 
        showActive ? org.org_status === 'active' : org.org_status !== 'active'
    );

    return (
        <>
            <Button size="sm" variant="outlined" className="flex text-blue-500 items-center gap-3" onClick={handleToggle}>
                <MdSwapHorizontalCircle size={20} /> Change Organisation
            </Button>

            <Dialog 
                open={isOpen} 
                handler={handleToggle}
                className="max-h-[95vh] flex flex-col"
            >
                <DialogBody className="flex-grow overflow-y-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-400">
                    <div className="flex justify-end mb-4">
                        <Switch
                            checked={showActive}
                            onChange={() => setShowActive(!showActive)}
                            color="blue"
                        />
                        <Typography className="text-gray-700 pl-2">Show Active Organisations</Typography>
                    </div>
                    {organisationsLoading && (
                        <LoadingIcon showText={true} />
                    )}
                    {organisationsError && (
                        <Typography color="red">Error fetching organisations: {organisationsError.message}</Typography>
                    )}
                    
                    <List>
                        {filteredOrganisations?.map((org) => (
                            <ListItem
                                key={org.id} 
                                onClick={() => changeOrganisation(org)}
                                className="gap-3"
                            >
                                <ListItemPrefix>
                                    <AvatarLogo
                                        src={org.logo_url}
                                        alt={`${org.title} logo`}
                                        size='xs'
                                        initialsCount={2}
                                        displayType='logo'
                                    />
                                </ListItemPrefix>
                                <FaDotCircle color={org.org_status === 'active' ? 'green' : 'pink'} />
                                {org.title}
                                <ListItemSuffix>
                                    {currentOrgId !== org.id && <Button size="sm" variant="outlined" color="blue">Select</Button>}
                                </ListItemSuffix>
                            </ListItem>
                        ))}
                    </List>
                    
                </DialogBody>
                <DialogFooter className="sticky bottom-0 bg-white border-t border-gray-300">
                    <Button color="pink" onClick={handleToggle}>
                        Cancel
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
};
SelectOrganisationDialog.propTypes = {
    currentOrgId: PropTypes.number,
    onChangeOrganisation: PropTypes.func,
};
export default SelectOrganisationDialog;