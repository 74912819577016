import React from 'react';
import PropTypes from 'prop-types';
import RatingDonut from "../shared/RatingDonut";

import OverviewCard from './OverviewCard';

export default function Overview_SCA({ overviewData, icon, title, PopoverComponent}) {
    
    return (
                <OverviewCard
                    Icon={icon}
                    title={title}
                    PopoverComponent={PopoverComponent}
                    ChartComponent={<RatingDonut rating={Number(overviewData?.overallRating)} useLetters />}
                    additionalInfo="Vulnerabilities"
                    additionalMetric={overviewData?.vulnerabilityCount}
                    loading={!overviewData}
                    runtime={overviewData?.runtime}
                />
        );   
};

Overview_SCA.propTypes = {
    overviewData: PropTypes.shape({
        overallRating: PropTypes.number,
        vulnerabilityCount: PropTypes.number,
        runtime: PropTypes.string
    }),
    icon: PropTypes.element,
    title: PropTypes.string,
    PopoverComponent: PropTypes.elementType
};