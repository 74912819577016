import { useAuthenticatedQuery } from '../../useAuthenticatedQuery';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config';
import { useAuth } from '../../../components/navigation/Authentication';

export default function useOrganisation(orgId = null) {
  const { user: authUser } = useAuth();

  // Define the query function here, taking tokenResponse as a parameter
  const fetchOrganisationDetails = async (token) => {
  const queryString = orgId ? `?org_id=${orgId}` : '';

  // Construct the URL
  const url = `${API_BASE_URL}disqoadmin/organisation${queryString}`;
    
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`, // Ensure this token is correctly being retrieved
          'x-functions-key': FUNCTION_KEY
        },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to load user details in useOrganisation', error);
      throw error;
    }
  };

  // Concatenate orgId to ensure uniqueness of the query key per organisation
  const queryKey = ['organisation', orgId || 'home'];
  
  const projectScope = [PROJECT_SCOPE];
  const { data: orgData = {}, isLoading, refetch, error, isWaiting } = useAuthenticatedQuery(queryKey, fetchOrganisationDetails, projectScope, {
    staleTime: 5 * 60 * 1000,
    enabled: !!authUser?.idTokenClaims
  });

  if (error) {
    console.error('Error fetching organisation details:', error);
  }

  return { orgData, isLoading, refetch, error, isWaiting };
}

useOrganisation.propTypes = {
  orgId: PropTypes.number
};