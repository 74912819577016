import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { ThemeProvider } from "@material-tailwind/react";
import * as Sentry from "@sentry/react";
import { SENTRY_DSN, APP_ENVIRONMENT } from './config'

import ErrorBoundary from './components/ErrorBoundary.jsx';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    /*
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/disqo-dashboard\.azurewebsites\.net/],
    }),
    new Sentry.Replay(),
    */
  ],
  // Performance Monitoring
  tracesSampleRate: 0, // Capture 10% of the transactions
  replaysSessionSampleRate: 0, // Sample rate at 1%
  replaysOnErrorSampleRate: 0, // Sample rate at 10% for errors
  environment: APP_ENVIRONMENT || 'Local Development',
});

ReactDOM.createRoot(document.getElementById('root')).render(
  
    <ThemeProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ThemeProvider>
)