import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from "@sentry/react";
import { IS_DEBUG } from '../config';
import { ErrorCard, CARD_TYPES } from './cards/ErrorCard';
import { Typography } from '@material-tailwind/react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorMessage: '' };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ errorMessage: error.message });

        if (IS_DEBUG) {
            console.error("ErrorBoundary caught an error", error, errorInfo);
        } else {
            Sentry.captureException(error);
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="flex flex-col justify-center items-center p-4 mt-10 gap-3">
                    <Typography variant='h4'>Something went wrong!</Typography>
                    <ErrorCard cardType={CARD_TYPES.ERROR} />
                    <Typography variant='h6'>{this.state.errorMessage}</Typography>
                </div>
            );
        }

        return this.props.children; 
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node
};

export default ErrorBoundary;