import { Card, DonutChart, BarChart, Metric, Grid, Title } from "@tremor/react";
import PropTypes from 'prop-types';
import LoadingIcon from "../../navigation/LoadingIcon";

export default function FunctionalFirstRow({ projectData }) {
  
  let testStatusData;
  const valueFormatter = (number) => number.toString();

  if (projectData?.items && projectData?.statuses) {
  
    testStatusData = [
      { name: "Passed", count: projectData.statuses.passed || 0 },
      { name: "Failed", count: projectData.total - (projectData.statuses.passed || 0) }
    ];
  }
  
  return (
      <Grid numItemsMd={2} numItemsLg={3} className="gap-6 mb-5">
          <div className="flex flex-col gap-4 ">
            {projectData?.total ? (
                <Card className="flex w-full h-full flex-col">
                  <Title>Total Tests</Title>
                  <Metric>{projectData.total}</Metric>
                </Card>
            ) : (
              <Card className="flex flex-col justify-center items-center h-full">
                <LoadingIcon showText />
              </Card>
            )}

          </div>

        <Card className="flex flex-col justify-center items-center h-full">
            {testStatusData ? (
                    <div className="flex flex-col justify-between flex-grow w-full">
                        <Title>Pass Rate</Title>
                        <div className="flex-grow flex items-center">
                            <DonutChart
                                className="self-center"
                                data={testStatusData}
                                category="count"
                                index="name"
                                colors={["green", "pink"]}
                                label={`${Math.round(((projectData.statuses.passed || 0) / projectData.total) * 100)}%`}
                                valueFormatter={valueFormatter}
                                showAnimation
                                style={{ fontSize: '2rem' }}
                            />
                        </div>
                    </div>
            ) : (
                <LoadingIcon showText />
            )}
        </Card>

        {projectData?.totalDuration && projectData?.total ? (
                        <Card className="flex w-full h-full flex-col">
                          <Title >Average Duration</Title>
                          <Metric>{Math.round(projectData.totalDuration / projectData.total)} seconds</Metric>
                        </Card>
                ) : (
                  <Card className="flex flex-col justify-center items-center h-full">
                    <LoadingIcon showText />
                  </Card>
              )}

        <Card className="flex flex-col" >
          {projectData.browserData ? (
              <div className="flex flex-col justify-between flex-grow w-full">
                <Title>Browser Coverage</Title>
                <BarChart
                  className="mt-6"
                  data={projectData.browserData}
                  index="name"
                  colors={["green", "pink"]}
                  categories={["Passed", "Failed"]}
                  valueFormatter={valueFormatter}
                  yAxisWidth={48}
                  showAnimation
                  stack
                />
              </div>
          ) : (
            <LoadingIcon showText className="justify-self-center self-center" />
          )}
        </Card>

        <Card className="flex flex-col" >
          {projectData.areaData  ? (
              <div className="flex flex-col justify-between flex-grow w-full">
                <Title>Functionality Area</Title>
                <BarChart
                  className="mt-6"
                  data={projectData.areaData}
                  index="name"
                  colors={["green", "pink"]}
                  categories={["Passed", "Failed"]}
                  valueFormatter={valueFormatter}
                  yAxisWidth={48}
                  showAnimation
                  stack
                />
              </div>
          ) : (
            <LoadingIcon showText className="justify-self-center self-center" />
          )}
        </Card>

        <Card className="flex flex-col" >
          {projectData.priorityData ? (
              <div className="flex flex-col justify-between flex-grow w-full">
                        <Title className="self-start text-xl mb-4">Priority</Title>
                <BarChart
                  className="mt-6"
                  data={projectData.priorityData}
                  index="name"
                  colors={["green", "pink"]}
                  categories={["Passed", "Failed"]}
                  valueFormatter={valueFormatter}
                  yAxisWidth={48}
                  showAnimation
                  stack
                />
              </div>
          ) : (
            <LoadingIcon showText className="justify-self-center self-center" />
          )}
        </Card>
      </Grid>
  );
}

FunctionalFirstRow.propTypes = {
  projectData: PropTypes.object
};