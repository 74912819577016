import React from 'react';

import AdminLayoutOrganisation from '../../layouts/AdminLayoutOrganisation';
import Users from '../../components/admin/users/Users';

export default function AdminUsers() {

  return (
    <AdminLayoutOrganisation>
      <div className="mt-2 md:mt-1 p-0 md:p-6 bg-gray-50 rounded-lg border ">
          <Users />
      </div>
      
    </AdminLayoutOrganisation>
  );
}