// userValidationSchema.js

import * as yup from 'yup';
import tldjs from 'tldjs';

export const MAX_LENGTH_DISPLAY_NAME = 64;
export const MAX_LENGTH_GIVEN_NAME = 64;
export const MAX_LENGTH_SURNAME = 64;
export const MAX_LENGTH_EMAIL = 255;

export const DISPLAYNAME_REGEX = /^[a-zA-ZÀ-ž'@ -]+$/;
export const NAME_REGEX = /^[a-zA-ZÀ-ž' -]+$/;

export const userValidationSchema = yup.object().shape({
  displayName: yup.string()
    .required('Please enter a display name.')
    .max(MAX_LENGTH_DISPLAY_NAME, `Display name cannot exceed ${MAX_LENGTH_DISPLAY_NAME} characters.`)
    .matches(DISPLAYNAME_REGEX, 'Display name can only contain letters, hyphens, apostrophes, and @ sign.'),
  givenName: yup.string()
    .required('Please enter a given name.')
    .max(MAX_LENGTH_GIVEN_NAME, `Given name cannot exceed ${MAX_LENGTH_GIVEN_NAME} characters.`)
    .matches(NAME_REGEX, 'Given name can only contain letters, hyphens, and apostrophes.'),
  surname: yup.string()
    .required('Please enter a surname.')
    .max(MAX_LENGTH_SURNAME, `Surname cannot exceed ${MAX_LENGTH_SURNAME} characters.`)
    .matches(NAME_REGEX, 'Surname can only contain letters, hyphens, and apostrophes.'),
  email: yup.string()
    .required('Please enter an email address.')
    .max(MAX_LENGTH_EMAIL)
    .test('is-valid-email', 'Please enter a valid email address with a valid TLD.', (value) => {
      if (!value) return false;
      const emailParts = value.split('@');
      if (emailParts.length !== 2) return false; // Must have exactly one @
      const domain = emailParts[1];
      if (!domain.includes('.')) return false; // Must have at least one dot in the domain
      const tld = domain.split('.').pop().toLowerCase();
      return tldjs.tldExists(tld);
    }),
});