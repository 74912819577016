// OrganisationKeysDialog.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button, Alert } from '@material-tailwind/react';
import { FaKey } from "react-icons/fa";
import { IoAddCircle } from "react-icons/io5";
import OrganisationAPIKeys from './OrganisationAPIKeys.jsx';
import useOrganisationAPIKeyManager from '../../../datahooks/admin/organisation/useOrganisationAPIKeyManager.js';
import useOrganisationKeys from '../../../datahooks/admin/organisation/useOrganisationKeys.js';

import LoadingIcon from '../../navigation/LoadingIcon.jsx';

const OrganisationKeysDialog = ({organisationId, disabled = false}) => {
    const [isOpen, setIsOpen] = useState(false);

    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState('');

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const [addingNewKey, setAddingNewKey] = useState(false);
    const { organisationKeysData, organisationKeysLoading, organisationKeysError, refetchOrganisationKeys } = useOrganisationKeys(organisationId);

    const apiKeyUpdated = (aResponse) => {
        setShowSuccessAlert(true);
        refetchOrganisationKeys();
    }

    const apiKeySaveFailed = (error) => {
        setErrorAlertMessage(error?.errorResponse ? `Error ${error.errorResponse.code}: ${error.errorResponse.message}` : 'Unknown error')
        setShowErrorAlert(true);
    }

    const { performAction } = useOrganisationAPIKeyManager(apiKeyUpdated, apiKeySaveFailed);
    
    const handleToggle = () => setIsOpen(!isOpen);

    const doSave = (formData) => {
        // Ensure formData contains the required fields
        const payload = {
          new_org_id: organisationId,
          new_api_key: formData.api_key,
          new_description: formData.key_description,
        };
    
        performAction(payload, 'PUT'); // Perform the PUT operation
        setAddingNewKey(false); // Resetting the 'adding' state
    };

    const doDelete = (keyId) => {
        const payload = {
            api_key_id: keyId
        };
        performAction(payload, 'DELETE');
    }

    return (
        <>
            <Button 
                size="sm" 
                variant="outlined" 
                className="flex text-blue-500 items-center gap-3" 
                onClick={handleToggle}
                disabled={disabled}
            >
                <FaKey size={20} /> API Keys
            </Button>

            <Dialog open={isOpen} size="xl" handler={handleToggle} dismissible={false}>
                {organisationKeysLoading && <LoadingIcon showText />}
                {organisationKeysError && <Alert color="red">{organisationKeysError.message}</Alert>}
                {!organisationKeysLoading && !organisationKeysError && (
                    <OrganisationAPIKeys 
                        organisationKeysData={organisationKeysData}
                        addingNewKey={addingNewKey}
                        doSaveFunction={doSave}
                        doDeleteFunction={doDelete}
                        setAddingNew={setAddingNewKey}
                    />
                )}

                <Alert color="pink" open={showErrorAlert} onClose={() => setShowErrorAlert(false)} className='m-2 w-98'>{errorAlertMessage}</Alert>
                <Alert color="green" open={showSuccessAlert} onClose={() => setShowSuccessAlert(false)} className='m-2 w-98'>Changes saved.</Alert>

                <Dialog.Footer >
                    <div className="flex justify-between w-full">
                        <div className='flex gap-3'>
                            <Button 
                                variant='outlined' 
                                color='blue' 
                                className="flex items-center gap-3"
                                onClick={() => setAddingNewKey(true)}
                                disabled={addingNewKey}
                            >
                                <><IoAddCircle size={20} /> Add</>
                            </Button>
                        </div>

                        <div>
                        <Button color="pink" onClick={handleToggle} disabled={addingNewKey}>
                            Close
                        </Button>
                        </div>
                    </div>
                    </Dialog.Footer>
            </Dialog>
        </>
    );
};
OrganisationKeysDialog.propTypes = {
    organisationId: PropTypes.number,
    disabled: PropTypes.bool,
};

OrganisationKeysDialog.defaultProps = {
    disabled: false,
};
export default OrganisationKeysDialog;