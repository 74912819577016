import { useAuth } from '../components/navigation/Authentication.jsx';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

function UnauthenticatedLayout({ children }) {

    const { user } = useAuth();

    if (user) {
        return <Navigate to="/home" replace />;
    }

    return (
      <div>
        {children}
      </div>
    );
  }
  
  UnauthenticatedLayout.propTypes = {
    children: PropTypes.node,
  };

  export default UnauthenticatedLayout;  