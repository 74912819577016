import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import { Typography, Button, Navbar } from '@material-tailwind/react';
import { Card, Title, TextInput } from "@tremor/react";
import useUser from '../datahooks/useUser.js';
import useUserManager from '../datahooks/admin/user/useUserManager.js';
import { useQueryClient } from 'react-query';
import LoadingIcon from '../components/navigation/LoadingIcon.jsx';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout.jsx';
import ImageUploader from '../components/admin/general/ImageUploader.jsx';
import AlertManager from '../components/general/AlertManager.jsx';
import { MSAL_RESET_URL } from '../config.js';
import { userValidationSchema, MAX_LENGTH_DISPLAY_NAME, MAX_LENGTH_EMAIL, MAX_LENGTH_GIVEN_NAME, MAX_LENGTH_SURNAME } from '../datahooks/metadata/ValidationUtils.js';

export default function UserProfile() {
  const { user: userDetails, refetch: refetchUser } = useUser();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const imageUploaderRef = useRef(null);
  const [imageSelected, setImageSelected] = useState(false);

  const queryClient = useQueryClient();

  const userUpdated = () => {
    setSuccessMessage("User profile updated successfully.");
    refetchUser();

    setImageSelected(false); // Reset image selection state
    imageUploaderRef?.current?.resetImage(); // Reset image uploader

    // Invalidate the user list in the admin section
    queryClient.invalidateQueries(/^users_/);
  };

  const userSaveFailed = (error) => {
    setErrorMessage(`Failed to save user profile: ${error.message || 'Unknown error'}`);
  };

  const { performAction } = useUserManager(userUpdated, userSaveFailed);

  const initialValues = {
    displayName: userDetails?.display_name || '',
    givenName: userDetails?.given_name || '',
    surname: userDetails?.surname || '',
    email: userDetails?.email_address || '',
    image_url: userDetails?.image_url || null,
  };

  const handleSubmit = async (values) => {
    // Validate the form
    try {
      await userValidationSchema.validate(values, { abortEarly: false });

      if (imageSelected) {
        imageUploaderRef.current.uploadImage(); // Call upload function if image is selected
      } else {
        // Save form data if no image upload is needed
        await doSave();  // Make sure doSave is awaited
      }
    } catch (error) {
      // If the form is invalid, capture and set the validation errors
      const validationErrors = error.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      formik.setErrors(validationErrors);
    }
  };

  const doSave = async (imagePath = null) => {
    const updatePayload = { "edit_user_id": userDetails.id };

    if (formik.values.displayName !== initialValues.displayName) {
      updatePayload.new_display_name = formik.values.displayName;
    }

    if (formik.values.surname !== initialValues.surname) {
      updatePayload.new_surname = formik.values.surname;
    }

    if (formik.values.givenName !== initialValues.givenName) {
      updatePayload.new_given_name = formik.values.givenName;
    }

    if (imagePath && initialValues.image_url !== imagePath) {
      updatePayload.new_image_url = imagePath;
    }

    if (formik.values.email !== initialValues.email) {
      updatePayload.new_email_address = formik.values.email;
    }

    if (Object.keys(updatePayload).length > 1) {
      return performAction(updatePayload, 'POST');
    }
  };

  const handleImageSelected = (file) => {
    setImageSelected(true);
  };

  const handleReset = () => {
    formik.resetForm(); // Reset form values
    setImageSelected(false); // Reset image selection state
    imageUploaderRef?.current?.resetImage(); // Reset image uploader
  };

  const formik = useFormik({
    initialValues,
    validationSchema: userValidationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  if (!userDetails) {
    return <LoadingIcon showText />;
  }

  return (
    <AuthenticatedLayout>
      <div className="mt-1 p-0 md:p-6 bg-gray-50 rounded-lg border ">
        <Card className="flex flex-col w-full h-full text-blue-500 items-center">
          <Title>User Profile</Title>
      
            <form onSubmit={formik.handleSubmit} className="flex flex-col justify-center w-full max-w-4xl">

              <Typography variant="h6" color="blue" className="mt-4">Display Name</Typography>
              <TextInput
                size="lg"
                name="displayName"
                value={formik.values.displayName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.displayName && formik.errors.displayName}
                errorMessage={formik.errors.displayName}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                maxLength={MAX_LENGTH_DISPLAY_NAME} //Plus 1 limits the input but ensures a suitable error message is displayed
              />

              <Typography variant="h6" color="blue" className="mt-4">Surname</Typography>
              <TextInput
                size="lg"
                name="surname"
                value={formik.values.surname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.surname && formik.errors.surname}
                errorMessage={formik.errors.surname}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                maxLength={MAX_LENGTH_SURNAME}
              />

              <Typography variant="h6" color="blue" className="mt-4">Given Name</Typography>
              <TextInput
                size="lg"
                name="givenName"
                value={formik.values.givenName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.givenName && formik.errors.givenName}
                errorMessage={formik.errors.givenName}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                maxLength={MAX_LENGTH_GIVEN_NAME}
              />

              <Typography variant="h6" color="blue" className="mt-4">Email Address</Typography>
              <TextInput
                size="lg"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && formik.errors.email}
                errorMessage={formik.errors.email}
                maxLength={MAX_LENGTH_EMAIL}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              />

              <Typography variant="h6" color="blue" className="mt-4">Profile Picture</Typography>
              <ImageUploader
                ref={imageUploaderRef}
                alt={formik.values.displayName + " profile image"}
                imgSrc={formik.values.image_url || ''}
                onSavePath={(path) => doSave(path)}
                imageType="user"
                imageId={userDetails.id}
                onImageSelect={handleImageSelected}
                displayType='avatar'
              />

              <AlertManager
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
              />

              <Navbar className="m-0 p-0 mt-5 flex items-center justify-end w-full p-4 gap-4 bg-gray-100">
                <a href={MSAL_RESET_URL} rel="noopener noreferrer">
                  <Button
                    color="blue"
                    variant="outlined"
                    className="flex items-center gap-3 self-start"
                  >
                    Reset Password
                  </Button>
                </a>
                <div className="ml-auto flex items-center gap-4">
                  <Button
                    color="blue"
                    variant="outlined"
                    onClick={() => handleReset()}
                    disabled={!formik.dirty && !formik.isSubmitting && !imageSelected}
                    className="flex text-blue-500 items-center gap-3"
                  >
                    Reset
                  </Button>
                  <Button
                    color="blue"
                    type="submit"
                    disabled={!formik.dirty && !formik.isSubmitting && !imageSelected}
                    className="flex items-center gap-3"
                  >
                    Save
                  </Button>
                </div>
              </Navbar>
            </form>
    
        </Card>
      </div>
    </AuthenticatedLayout>
  );
}
