import React from 'react';
import PropTypes from 'prop-types';
import useTestResult from '../../datahooks/useTestResult';
import LoadingIcon from '../navigation/LoadingIcon';
import { TabPanel, Typography } from '@material-tailwind/react';

import FunctionalUITestType from './functional/FunctionalUITestType';
import APIOverview from './api/APIOverview';
import StaticCodeTestType from './staticcode/StaticCodeTestType';
import PerformancePage from './performance/Performance';
import Accessibility from './accessibility/Accessibility';
import { ErrorCard, CARD_TYPES } from './ErrorCard';

const TEST_TYPE_COMPONENTS = {
    1: StaticCodeTestType,
    2: APIOverview,
    3: FunctionalUITestType,
    4: PerformancePage,
    5: Accessibility
};

const DefaultComponent = () => <div>Default Test Type Content</div>;

const TestTypeTabPanel = ({ releaseId, testTypeId, projectId }) => {
    const { testResult, isLoading, isError } = useTestResult(testTypeId, releaseId);
    const TestComponent = TEST_TYPE_COMPONENTS[testTypeId] || DefaultComponent;
    
    let content;
    if (isLoading) {
        content = <LoadingIcon showText />;
    } else if (isError) { //Error in retrieving data or error in API call
        content = <p>Error: The test has failed to complete.</p>;
    } else if (testResult) {
        switch (testResult.status) {
            case 'success':
                content = <TestComponent resultData={testResult} projectId={projectId}  />;
                break;
            case 'pending':
            case 'ready_to_collect':
                content =( 
                    <>
                        <ErrorCard cardType={CARD_TYPES.RUNNING} />
                        <Typography className='mt-5 text-center'>This test type is currently running.</Typography>
                        <Typography className='text-center'>Results will be available once complete.</Typography>
                    </>
                );
                break;
            case 'error':
                content = (
                    <>
                        <ErrorCard cardType={CARD_TYPES.ERROR} />
                        <Typography className='mt-5 text-center'>There has been an error while running this test type.</Typography>
                    </>
                ) 
                break;
            default:
                content = (
                    <>
                    <ErrorCard cardType={CARD_TYPES.EMPTY} />
                    <div className='m-2'>
                        <Typography className='mt-5 text-center'>There is currently no data for this test type.</Typography>
                        <Typography className='text-center'>The test type may not have been run for this release.</Typography>
                    </div>
                </>);
        }
    } else {
        content = <p>Unknown status.</p>;
        console.log("Fallen through for test type", testTypeId, testResult);
    }
    
    return (
        <TabPanel key={testTypeId} value={testTypeId.toString()}>
            {content}
        </TabPanel>
    );
};

TestTypeTabPanel.propTypes = {
    releaseId: PropTypes.string,
    testTypeId: PropTypes.number,
    projectId: PropTypes.number,
};

export default TestTypeTabPanel;