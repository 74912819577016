import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Typography, Button } from '@material-tailwind/react';

import { Card, TextInput, Textarea } from "@tremor/react";

import { ErrorCard, CARD_TYPES } from '../../cards/ErrorCard'

import * as yup from 'yup';
import DisableDialog from '../general/DisableDialog';

const organisationAPIKeysSchema = yup.object().shape({
    api_key: yup
      .string()
      .required('Please enter an API key.')
      .max(100, 'The API key should not exceed 100 characters.'),
    key_description: yup
      .string()
      .optional()
      .max(1000, 'The description should not exceed 1000 characters.') // Example max length
      .matches(/^[a-zA-Z0-9 ]*$/, 'The intro text contains invalid characters.'), // Alphanumeric and spaces only
  });


const OrganisationAPIKeys = ({ organisationKeysData = [], addingNewKey=false, doSaveFunction = () => {}, setAddingNew=() => {}, doDeleteFunction = () => {} }) => {
  const [showActiveKeys, setShowActiveKeys] = useState(true);  
  
  const [formData, setFormData] = useState({ api_key: '', key_description: '' });
  const [validationErrors, setValidationErrors] = useState({});

  const handleToggleKeys = () => {
    setShowActiveKeys(!showActiveKeys);
  };

  const filteredKeys = organisationKeysData
    ? organisationKeysData.filter((key) => 
        showActiveKeys ? key.key_status === 'active' : key.key_status !== 'active'
      )
    : [];

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    try {
      // Validate the specific field against the schema
      await organisationAPIKeysSchema.validateAt(name, { [name]: value });
      setValidationErrors((prev) => ({ ...prev, [name]: null }));
    } catch (error) {
    setValidationErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleSave = async () => {
    try {
      // Validate the entire form before submission
      await organisationAPIKeysSchema.validate(formData, { abortEarly: false });
      
      doSaveFunction(formData);

    } catch (error) {
      const errors = error.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      setValidationErrors(errors);
    }
  };

  const cancelAddKey = () => {
    if (setAddingNew) {
      setAddingNew(false);
    }
  }

  const handleDeleteClick = (keyId, newStatus) => {
    doDeleteFunction(keyId);
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-bold">Organisation API Keys</h3>
        <Switch
          color="blue"
          label={`Show ${!showActiveKeys ? 'Inactive' : 'Active'} Keys`}
          checked={showActiveKeys}
          onChange={handleToggleKeys}
          disabled={addingNewKey}
        />
      </div>

      <div className="flex flex-col space-y-2">
        {!addingNewKey ? (
          <>
            <div className="flex justify-between font-bold bg-gray-200 p-2 gap-2">
              <div className="w-1/3">API Key</div>
              <div className="w-1/6">Creation Date</div> 
              <div className="w-1/6">Valid Until</div>
              <div className="w-1/4">Description</div>
              <div className="w-1/12"></div>
            </div>
            {filteredKeys.length === 0 ? (
              <div className="flex flex-col justify-center items-center p-4 bg-gray-100">
                <ErrorCard cardType={CARD_TYPES.EMPTY} />
                <Typography variant='h6'>No API keys found. Add a new one to get started.</Typography>
              </div>
            ) : (
            filteredKeys.map((key) => (
              <div className="flex justify-between p-2 gap-2" key={key.id}>
                <div className="w-1/3 break-all">{key.api_key}</div>
                <div className="w-1/6">{key.creation}</div>
                <div className="w-1/6">{key.valid_until ? key.valid_until : 'N/A'}</div>
                <div className="w-1/4">{key.key_description}</div>
                <div className="w-1/12">{key.key_status == 'active' && <DisableDialog showText={false} theId={key.id} theStatus='active' theType='API key' onConfirm={handleDeleteClick} />}</div>
              </div>
            ))
          )};
          </>
      ) : (
        <Card className="flex flex-col w-full h-full items-center">
          <form className="flex flex-col gap-4 w-full max-w-4xl">
              <Typography variant="h5" className="text-blue-500 mt-2">
                  Adding New API Key
              </Typography>

              <Typography variant="h5" className="text-blue-500 mt-2">API Key</Typography>
              <TextInput
                  type="text"
                  color="lightBlue"
                  size="regular"
                  placeholder="API Key"
                  label="API Key"
                  name="api_key"
                  value={formData.api_key}
                  onChange={handleInputChange}
                  className="w-full"
                  error={!!validationErrors.api_key}
                  errorMessage={validationErrors.api_key}
              />

              <Typography variant="h5" className="text-blue-500 mt-2">Description</Typography>
              <Textarea
                  className="material-tailwind-input w-full"
                  placeholder="Description"
                  name="key_description"
                  onChange={handleInputChange}
                  label="Description"
                  value={formData.key_description}
                  error={!!validationErrors.key_description}
                  errorMessage={validationErrors.key_description}
              />
          </form>

          <div className="flex justify-end gap-3 w-full mt-4">
            <Button color="pink" onClick={() => cancelAddKey()}>
                Cancel
            </Button>
            <Button color="blue" onClick={handleSave}>
                Save
            </Button>
          </div>

        </Card>
      )}
      </div>
    </div>
  );
};

OrganisationAPIKeys.propTypes = {
  organisationKeysData: PropTypes.array,
  addingNewKey: PropTypes.bool,
  doSaveFunction: PropTypes.func,
  setAddingNew: PropTypes.func,
  doDeleteFunction: PropTypes.func,
};

export default OrganisationAPIKeys;
