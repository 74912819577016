// OrganisationKeysDialog.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import { FaStopCircle, FaPlayCircle } from "react-icons/fa";

const DisableOrganisationDialog = ({orgStatus, organisationId, onUpdate, disabled = false}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => setIsOpen(!isOpen);

    const handleConfirm = () => {
        // Construct payload based on current status
        const newStatus = orgStatus === 'active' ? 'inactive' : 'active';
        const payload = {
            organisation_id: organisationId,
            new_org_status: newStatus
        };

        onUpdate(payload);  // Call the update function passed from parent
        handleToggle();     // Close the dialog
    };

    return (
        <>
            <Button
                size="sm"
                variant="outlined" 
                className="flex text-blue-500 items-center gap-3" 
                onClick={handleToggle}
                disabled={disabled}
            >
                {orgStatus == 'active' ?
                    <><FaStopCircle size={20} /> Disable</>
                :   <><FaPlayCircle size={20} /> Enable</>
                }
            </Button>

            <Dialog open={isOpen} handler={handleToggle}>
                <DialogHeader>{orgStatus == 'active' ? 'Disable' : 'Enable'} Organisation?</DialogHeader>
                <DialogBody className='text-base'>Are you sure you want to {orgStatus == 'active' ? 'disable' : 'enable'} this organisation?</DialogBody>
                <DialogFooter className='flex justify-end gap-3'>
                    <Button color='pink' onClick={handleToggle}>Cancel</Button>
                    <Button color='blue' onClick={handleConfirm}>Confirm</Button>
                </DialogFooter>
            </Dialog>
        </>
    );
};
DisableOrganisationDialog.propTypes = {
    orgStatus: PropTypes.string,
    organisationId: PropTypes.number,
    onUpdate: PropTypes.func,
    disabled: PropTypes.bool
};
export default DisableOrganisationDialog;