import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import useUser from '../../useUser.js';

export default function useOrganisationKeys(orgId) {
  const { user:userDetails } = useUser();

  const fetchOrganisationKeys = async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}disqoadmin/organisation_keys?org_id=${orgId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to load organisation keys in useOrganisationKeys', error); // Error during fetch
      throw error;
    }
  };
  
  const { data: organisationKeysData = [], isLoading, refetch, error } = useAuthenticatedQuery(`organisations_keys_${userDetails?.id}_${orgId}`, fetchOrganisationKeys, [PROJECT_SCOPE], {
    staleTime: Infinity, // The data will never be considered stale. If the result is in a pending state, a refetch button will be available
  });

  // Additional logs to monitor the state changes
  if (error) {
    console.error('Error fetching organisation keys:', error);
  }
  
  return { organisationKeysData, organisationKeysLoading: isLoading, refetchOrganisationKeys: refetch, organisationKeysError: error };
}

useOrganisationKeys.propTypes = {
  orgId: PropTypes.number
}