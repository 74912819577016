import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, Typography } from '@material-tailwind/react';
import { FaRegCheckCircle } from 'react-icons/fa';
import { VscError } from 'react-icons/vsc';

const AlertManager = ({ successMessage, setSuccessMessage, errorMessage, setErrorMessage }) => {
  // Auto-dismiss alerts after a delay
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 3000);
      return () => clearTimeout(timer);
    }
    if (errorMessage) {
      const timer = setTimeout(() => setErrorMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, setSuccessMessage, errorMessage, setErrorMessage]);

  return (
    <>
      {successMessage && (
        <Alert color="green" onClose={() => setSuccessMessage('')}>
          <div className="flex items-center mt-2">
            <FaRegCheckCircle size={30} className="mr-2" data-testid="success-icon" />
            <Typography variant="h6" className="inline-block ml-2">
              {successMessage}
            </Typography>
          </div>
        </Alert>
      )}
      {errorMessage && (
        <Alert color="pink" onClose={() => setErrorMessage('')}>
          <div className="flex items-center mt-2">
            <VscError size={30} className="mr-2" data-testid="error-icon" />
            {errorMessage}
          </div>
        </Alert>
      )}
    </>
  );
};

AlertManager.propTypes = {
  successMessage: PropTypes.string,
  setSuccessMessage: PropTypes.func,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func,
};

export default AlertManager;
