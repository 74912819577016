import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from "@tremor/react";
import { Button } from '@material-tailwind/react';

const EditableTextInput = ({
  label,
  name,
  type = 'text',
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  errorMessage,
  setFieldValue,  // Pass Formiks setFieldValue function
  initialValue  // Pass the initial value to reset on cancel
}) => {
    const [editMode, setEditMode] = useState(false);

    const handleEdit = () => {
        setEditMode(true);
        setFieldValue(name, '');  // Clear the input when entering edit mode
    };

    const handleCancel = () => {
        setFieldValue(name, initialValue);  // Reset to the initial value
        setEditMode(false);
    };

    const handleSave = () => {
        setEditMode(false);  // Close edit mode; value is already updated in Formik's state via onChange
    };

    return (
        <>
            <label>{label}</label>
            {editMode ? (
                <div className='flex flex-row gap-2 items-center'>
                    <TextInput
                        type={type}
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        autoFocus
                        error={error}
                        errorMessage={errorMessage}
                        placeholder={placeholder}
                    />
                    <Button color="blue" variant="outlined" onClick={handleCancel}>Cancel</Button>
                    <Button color="blue" onClick={handleSave}>Done</Button>
                </div>
            ) : (
                <div className='flex flex-row gap-2 items-center'>
                    <TextInput
                        type={type}
                        name={name}
                        value={value}
                        readOnly
                        disabled
                    />
                    <Button color="blue" onClick={handleEdit}>Edit</Button>
                </div>
            )}
        </>
    );
};


EditableTextInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    setFieldValue: PropTypes.func,
    initialValue: PropTypes.string,
};

export default EditableTextInput;
