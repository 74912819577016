import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_ADMIN_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import useUser from '../../useUser.js';

export default function useUserList(orgId = null) {
  const { user:userDetails } = useUser();

  const fetchUsers = async (token) => {
    try {
      const response = await axios.get(`${API_ADMIN_URL}users?org_id=${orgId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to load users in useUserList', error); // Error during fetch
      throw error;
    }
  };
  
  const { data: usersData = [], isLoading, refetch, error } = useAuthenticatedQuery(`users_${userDetails?.id}_${orgId}`, fetchUsers, [PROJECT_SCOPE], {
    staleTime: 300000, //Data will be considered stale after 5 minutes
  });

  // Additional logs to monitor the state changes
  if (error) {
    console.error('Error fetching users:', error);
  }
  
  return { usersData, loadingUsers: isLoading, refetchUsers: refetch, usersError: error };
}

useUserList.propTypes = {
  orgId: PropTypes.number
}