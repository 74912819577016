import { Card, DonutChart, Grid, Title, Metric } from "@tremor/react";
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Accordion, AccordionHeader, AccordionBody, Typography, Chip } from '@material-tailwind/react';
import LoadingIcon from "../../navigation/LoadingIcon";
import { MdOutlineTimer } from "react-icons/md";
import PopAPIIntegration from "../shared/popupExplanations/PopAPIIntegration";
import { getTimeDifference } from "../../../datahooks/metadata/DataUtils";
import APIHeaderContent from "./APIHeaderContent";
import APIBodyContent from "./APIBodyContent";

import TrendChart from "../shared/TrendChart";

/**
 * APIOverview component displays an overview of API integration test results.
 * @param {Object} props - Component properties.
 * @param {Object} props.resultData - The result data containing API test results.
 * @returns {JSX.Element} The rendered component.
 */
export default function APIOverview({ resultData, projectId }) {

  const barKeys = [
    { dataKey: "passed", name: "Passed Tests" },
    { dataKey: "failed", name: "Failed Tests" },
  ];

  let testStatusData, passPercentage;

  // Extract the result_json from resultData
  const apiData = resultData?.result_json;

  // Formatter for values in charts
  const valueFormatter = (number) => number.toString();
  const [open, setOpen] = useState(0);

  // Store UUIDs for issues without an ID
  const [uuidMap, setUuidMap] = useState({});

  // Handle opening and closing of accordion sections
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  // Generate UUIDs for issues without an ID and store them in state
  useEffect(() => {
    if (apiData?.apis) {
      const newUuidMap = {};
      apiData.apis.forEach(api => {
        api.issues.forEach(issue => {
          // Create a unique key for each issue based on the issue name
          const uniqueKey = `${issue.name}`;
          if (!issue.id && !uuidMap[uniqueKey]) {
            newUuidMap[uniqueKey] = uuidv4();
          }
        });
      });
      setUuidMap(newUuidMap); // Reset UUIDs when apiData changes
    }
  }, [apiData]);

  // Initialise test status data and pass percentage
  if (apiData) {
    testStatusData = [
      { name: "Passed", count: apiData.totalPassed },
      { name: "Failed", count: apiData.totalTests - apiData.totalPassed }
    ];
    passPercentage = [
      { name: "Passed", count: apiData.overallPassRate },
      { name: "Failed", count: 100 - apiData.overallPassRate }
    ];
  }

  return (
    <>
      {/* Header section with title and timer */}
      <div className='flex flex-row items-center mb-5 pb-2 border-b border-gray-400 gap-2'>
        <div className="w-full mb-1 flex flex-row">
          <Typography variant="h3" className="text-blue mr-1">API Integration</Typography>
          <PopAPIIntegration page size={25} />
        </div>
        {(resultData?.creation && resultData?.last_update) && <Chip color="blue" value={getTimeDifference(resultData.creation, resultData.last_update)} variant="outlined" icon={<MdOutlineTimer size={20}/>} />}
        {resultData?.env && <Chip color="blue" value={resultData.env} />}
      </div>

      <TrendChart
          projectId={projectId}
          metricType="percentage"
          yAxisDomain={[0, 100]}
          testTypeId={2}
          barKeys= {barKeys}
      />

      {/* Grid section with test statistics */}
      <Grid numItemsMd={2} numItemsLg={3} className="gap-6 mb-5">
        <Card className="flex flex-col justify-center items-center h-full">
          {testStatusData ? (
            <div className="flex flex-col justify-between flex-grow w-full">
              <Title>Test Pass/Failures</Title>
              <div className="flex-grow flex items-center">
                <DonutChart
                  className="self-center"
                  data={testStatusData}
                  category="count"
                  index="name"
                  colors={["green", "pink"]}
                  label={apiData.totalPassed}
                  valueFormatter={valueFormatter}
                  showAnimation
                  style={{ fontSize: '2rem' }}
                />
              </div>
            </div>
          ) : (
            <LoadingIcon showText />
          )}
        </Card>

        <Card className="flex flex-col justify-center items-center h-full">
          {apiData ? (
            <div className="flex flex-col justify-between flex-grow w-full">
              <Title>Pass rate</Title>
              <div className="flex-grow flex justify-center items-center">
                <DonutChart
                  className="self-center"
                  data={passPercentage}
                  category="count"
                  index="name"
                  colors={["green", "pink"]}
                  label={`${apiData.overallPassRate}%`}
                  valueFormatter={valueFormatter}
                  showAnimation
                  style={{ fontSize: '2rem' }}
                />
              </div>
            </div>
          ) : (
            <LoadingIcon showText />
          )}
        </Card>

        <div className="flex flex-col gap-4">
          {apiData ? (
            <Card className="flex w-full h-full flex-col">
              <Title>Total APIs Tested</Title>
              <Metric>{apiData.numberOfApis}</Metric>
            </Card>
          ) : (
            <Card className="flex flex-col justify-center items-center h-full">
              <LoadingIcon showText />
            </Card>
          )}

          {apiData ? (
            <Card className="flex w-full h-full flex-col">
              <Title>Total Tests</Title>
              <Metric>{apiData.totalTests}</Metric>
            </Card>
          ) : (
            <Card className="flex flex-col justify-center items-center h-full">
              <LoadingIcon showText />
            </Card>
          )}
        </div>
      </Grid>

      {/* Accordion section with detailed API information */}
      <Card>
        {apiData?.apis ? (
          apiData.apis.map((api, index) => (
            <Accordion className="text-base font-normal" key={api.name} open={open === index + 1}>
              <AccordionHeader onClick={() => handleOpen(index + 1)}>
                <APIHeaderContent api={api} open={open === index + 1} />
              </AccordionHeader>
              <AccordionBody>
                <APIBodyContent api={api} uuidMap={uuidMap} />
              </AccordionBody>
            </Accordion>
          ))
        ) : (
          <LoadingIcon showText />
        )}
      </Card>
    </>
  );
}

/**
 * Prop types for the APIOverview component.
 * @type {Object}
 */
APIOverview.propTypes = {
  resultData: PropTypes.shape({
    result_json: PropTypes.shape({
      totalPassed: PropTypes.number,
      totalTests: PropTypes.number,
      overallPassRate: PropTypes.number,
      numberOfApis: PropTypes.number,
      apis: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          passRate: PropTypes.number,
          count: PropTypes.number,
          issues: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              status: PropTypes.string,
              name: PropTypes.string,
              browser: PropTypes.string,
              os: PropTypes.string,
              duration: PropTypes.number
            })
          )
        })
      )
    }),
    creation: PropTypes.string,
    last_update: PropTypes.string,
    env: PropTypes.string
  }),
  projectId: PropTypes.number
};
