import React from 'react';
import LoadingIcon from '../components/navigation/LoadingIcon.jsx';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout.jsx';

import useProjects from '../datahooks/useProjects'; 

import { Card, Title  } from "@tremor/react";
import { Link } from 'react-router-dom';
import { Typography } from '@material-tailwind/react';
import FeatureIcon from '../components/general/FeatureIcon.jsx';
import AvatarLogo from '../components/general/AvatarLogo.jsx';

import { useOrgContext } from '../datahooks/admin/organisation/AdminOrgIDContext';

export default function Home() {
    const { projects, isLoading } = useProjects();  //Load list of projects from useProjects hook
    const { organisationData } = useOrgContext();

    const logoUrl = organisationData?.logo_url ? organisationData.logo_url : "/assets/logo/tiqtoq.png";

    return (
        <AuthenticatedLayout>
        <div className="mt-1 p-0 md:p-6 bg-gray-50 rounded-lg border ">
            <Card className="flex w-full h-full flex-col static">
                <Title className='hidden md:flex'>disQo Dashboard</Title>
                <div className="flex md:flex-row flex-col items-start">
                    <div className="md:order-2 order-1 w-full lg:w-1/6 md:w-1/5 flex-shrink-0 text-center mb-3">
                    <AvatarLogo
                        src={logoUrl}
                        alt={`${organisationData.title} logo`}
                        size='xl'
                        initialsCount={2}
                        displayType='logo'
                    />
                    </div>
                    <Typography className="md:order-1 order-2 flex-grow">
                        Welcome to the disQo Dashboard, the essential tool for overseeing the quality of your digital products and releases. The platform offers a comprehensive suite of features for in-depth User Interface analysis, robust API monitoring, detailed performance metrics, thorough security audits, and accessibility compliance. Designed for efficiency and ease of use, the disQo Dashboard is your reliable solution in enhancing and securing your digital offerings with precision and expertise.
                    </Typography>
                </div>
            </Card>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-1 p-6 sm:p-10 bg-gray-50 rounded-lg border mx-auto">
                
                {isLoading && (
                    <div className="col-span-1 md:col-span-2 lg:col-span-3 flex items-center justify-center h-60">
                        <LoadingIcon size={150} showText />
                    </div>
                )}
                
                {!isLoading && projects.length > 0 ? (
                    projects.map(project => (
                        <Link key={project.short_name} to={`/projects/${project.projectId}`} className="col-span-1 ">
                            <Card className="flex flex-col min-h-50 w-full relative p-2 h-full">
                                <div className="flex flex-row items-end border-b ">
                                    <AvatarLogo 
                                        src={project.logo_location} 
                                        alt={project.title + " logo"}
                                        displayType="logo"
                                    />
                                    <Title className="pl-2">{project.title}</Title>
                                </div>
                                <Typography variant='paragraph' className="mt-4 mb-4">{project.intro_text}</Typography>
                                <div className="flex-grow"></div>
                                <div className="w-full border-t p-3 -mb-4 -mr-4 mt-2 flex justify-end">
                                {project?.testTypeIds?.map((testTypeId) => (
                                    <FeatureIcon key={`${project.short_name}-${testTypeId}`}  testTypeId={testTypeId} />
                                ))}
                                </div>
    
                            </Card>
                        </Link>
                    )
                )) : (  //Projects loaded but none assigned to user
                    <div className="col-span-1 md:col-span-2 lg:col-span-3 text-center">
                        <Typography variant='paragraph' className="mt-4">No projects available.</Typography>
                    </div>
                )
            }
            </div>
        </AuthenticatedLayout>
    );
}