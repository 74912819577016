import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-tailwind/react';
import IssueStatus from "../../general/IssueStatus";
import { featureDetails } from '../../../datahooks/metadata/DataUtils';

const APIAccordionBodyContent = ({ api, uuidMap }) => {
  return (
    <div data-testid="api-body-content" className="grid grid-cols-5 gap-2 divide-y divide-gray-300 select-none">
      {api.issues.map((issue) => (
        <div key={issue.id || uuidMap[`${issue.name}`]} className="col-span-5">
          <div className="grid grid-cols-5 items-center">
            <IssueStatus status={issue.status} />
            <Typography variant="paragraph" className="p-2 font-medium" title="Test">{issue.name}</Typography>
            <Typography variant="paragraph" className="p-2 font-medium" title="Browser">{issue.browser}</Typography>
            <Typography variant="paragraph" className="p-2 font-medium" title="Operating">{issue.os}</Typography>
            <Typography variant="paragraph" className="p-2 font-medium" title="Duration">{issue.duration} sec</Typography>
          </div>
          {issue.id && featureDetails[2]?.detailLink && (
            <div className="col-span-5 flex justify-end mt-2">
              <Typography variant="paragraph">
                <a
                  href={featureDetails[2].detailLink.replace('{id}', issue.id)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 font-semibold"
                >
                  For more details, click here.
                </a>
              </Typography>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

APIAccordionBodyContent.propTypes = {
  api: PropTypes.shape({
    issues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        browser: PropTypes.string,
        os: PropTypes.string,
        duration: PropTypes.number
      })
    ).isRequired
  }).isRequired,
  uuidMap: PropTypes.object.isRequired,
};

export default APIAccordionBodyContent;