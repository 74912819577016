import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-tailwind/react';
import { getPriorityColour } from '../../../datahooks/metadata/DataUtils';

const PriorityChip = ({ priority }) => {  
  const chipColor = getPriorityColour(priority);

  return (
    <Chip color={chipColor} value={priority}>
      {priority}
    </Chip>
  );
};

PriorityChip.propTypes = {
  priority: PropTypes.string
};

export default PriorityChip;
