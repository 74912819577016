import * as Sentry from "@sentry/react";
import { IS_DEBUG } from "../../config";

export default function logError(errorMessage, error, additionalData = {}, logToSentry = true) {
    
    if (logToSentry) {
        // Capture the error in Sentry
        Sentry.withScope(scope => {
            // Add the additional data to the scope
            scope.setContext('Additional Data', additionalData);
            
            // Add a breadcrumb for additional context
            scope.addBreadcrumb({
                message: errorMessage
            });

            // Capture the original error directly to preserve the stack trace and original message
            Sentry.captureException(error);
        });
    }

    // Log the error to the console if debugging is enabled
    if (IS_DEBUG) {
        console.error(errorMessage, error, additionalData);
    }
}