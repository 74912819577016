import React from 'react';
import PropTypes from 'prop-types';
import { DonutChart } from "@tremor/react";

import OverviewCard from './OverviewCard';

export default function Overview_PER({ overviewData, icon, title, PopoverComponent}) {
    let performanceSucesses = [];
    //{"errorCount": 800, "sampleCount": 1000, "transactionsCount": 5}
    if (overviewData) {
        performanceSucesses = [
            { name: "Success", count: overviewData.sampleCount - overviewData.errorCount },
            { name: "Failures", count: overviewData.errorCount }
        ];
    }
    
    return (
                <OverviewCard
                    Icon={icon}
                    title={title}
                    PopoverComponent={PopoverComponent}
                    ChartComponent={<DonutChart
                        data={performanceSucesses}
                        category="count"
                        index="name"
                        colors={["green", "pink"]}
                        label={`${Math.round((1-(overviewData?.errorCount / overviewData?.sampleCount)) * 100)}%`}
                        showAnimation
                        style={{ fontSize: '2rem' }}
                        />}
                    additionalInfo="Transactions"
                    additionalMetric={overviewData?.transactionsCount}
                    loading={!overviewData}
                    runtime={overviewData?.runtime}
                />
        );   
};

Overview_PER.propTypes = {
    overviewData: PropTypes.shape({
        errorCount: PropTypes.number,
        sampleCount: PropTypes.number,
        transactionsCount: PropTypes.number,
        runtime: PropTypes.string
    }),
    icon: PropTypes.element,
    title: PropTypes.string,
    PopoverComponent: PropTypes.elementType
};