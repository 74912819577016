import React from 'react';
import PropTypes from 'prop-types';
import { DonutChart } from "@tremor/react";

import OverviewCard from './OverviewCard';

export default function Overview_API({ overviewData, icon, title, PopoverComponent}) {
    let apiPassPercentage = [];

    if (overviewData?.overallPassRate && typeof overviewData.overallPassRate === 'number' && overviewData.overallPassRate >= 0 && overviewData.overallPassRate <= 100) {
        apiPassPercentage = [
            { name: "Passed", count: overviewData.overallPassRate },
            { name: "Failed", count: 100 - overviewData.overallPassRate }
        ];
    } else {
        apiPassPercentage = [{ name: "Data Unavailable", count: 0 }];
    }

    return (
                <OverviewCard
                    Icon={icon}
                    title={title}
                    PopoverComponent={PopoverComponent}
                    ChartComponent={<DonutChart
                        data={apiPassPercentage}
                        fontSize="1rem"
                        category="count"
                        index="name"
                        colors={["green", "pink"]}
                        label={`${overviewData?.overallPassRate}%`}
                        
                        showAnimation
                        style={{ fontSize: '2rem' }}
                        />}
                    additionalInfo="Total APIs Tested"
                    additionalMetric={overviewData?.numberOfApis}
                    loading={!overviewData}
                    runtime={overviewData?.runtime}
                />
        );   
};

Overview_API.propTypes = {
    overviewData: PropTypes.shape({
        overallPassRate: PropTypes.number,
        numberOfApis: PropTypes.number,
        runtime: PropTypes.string
    }),
    icon: PropTypes.element,
    title: PropTypes.string,
    PopoverComponent: PropTypes.elementType
};