import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMsal } from '../../datahooks/useMsal';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import useUpdateLastLogin from '../../datahooks/useUpdateLastLogin';
import { PROJECT_SCOPE } from '../../config';
import logError from '../../datahooks/metadata/ErrorLogging';
import { useQueryClient } from 'react-query';

/**
 * Context for storing authentication-related data and functions.
 */
export const AuthContext = createContext();

/**
 * Provider component for the AuthContext.
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Child components.
 * @returns {React.ReactNode} - Rendered component.
 */
export const AuthProvider = ({ children }) => {
    const { msalInstance, isInitialised } = useMsal();
    const queryClient = useQueryClient();
    const [user, setUser] = useState(null);
    const [loginError, setLoginError] = useState(null);
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    const doUpdateLastLogin = useUpdateLastLogin(
        () => {}, // No action on success
        (error) => console.log("Failed to set user last login date", error)
    );

    /**
     * Effect hook for handling authentication logic on component mount and update.
     */
    useEffect(() => {
        if (isInitialised) {
            msalInstance.handleRedirectPromise().then(async (response) => {
                if (response?.account) {
                    handlePostLogin(response.account, true); // true indicates this is a redirect after full login
                } else {
                    const accounts = msalInstance.getAllAccounts();
                    if (accounts.length > 0) {
                        handlePostLogin(accounts[0], false); // false indicates this is auto-login
                    }
                }
            }).catch(e => {
                console.error('Error processing redirect:', e);
                // Handle or log error appropriately
            });
        }
    }, [msalInstance, isInitialised]);

    /**
     * Handle actions post-login, including clearing the cache if this is a full login.
     */
    const handlePostLogin = async (account, isFullLogin) => {
        if (isFullLogin) {
            // Clear cache and local storage on full login
            console.log('Full login detected, clearing cache and local storage.');
            queryClient.clear();
            localStorage.removeItem('editingOrganisationId');
            sessionStorage.setItem('sessionStart', new Date().getTime());
        }

        setUser(account);

        try {
            const token = await acquireTokenSilent([PROJECT_SCOPE]);
            doUpdateLastLogin(account.idTokenClaims?.extension_DatabaseUserID, token);
        } catch (e) {
            console.error('Token acquisition failed:', e);
            setLoginError('Your session has expired. Please log in again.');
            signOut();  // Clear the current session
        }
    };

    /**
     * Function for initiating the sign-in process.
     */
    const signIn = () => {
        if (isInitialised) {
            setIsAuthenticating(true);
            performLogin();
        } else {
            console.warn('MSAL instance not initialised yet.');
        }
    };

    /**
     * Function for initiating the sign-out process.
     */
    const signOut = () => {
        if (isInitialised) {
            queryClient.clear(); // Clear the React Query cache
            msalInstance.logout(); // Proceed with the logout process
            localStorage.removeItem('organisationData'); // Remove the stored organisation ID
            sessionStorage.removeItem('sessionStart'); // Clear the session start marker
            setUser(null); // Reset user state after logout
        } else {
            console.warn('MSAL instance not initialised yet for signOut.');
        }
    };

    /**
     * Function for acquiring an access token silently.
     * @param {string[]} scopes - Scopes for the token request.
     * @returns {Promise<string>} - Promise that resolves to the access token.
     * @throws {Error} - If MSAL instance is not initialised or no accounts found.
     */
    const acquireTokenSilent = async (scopes) => {
        if (!isInitialised) {
            console.warn('MSAL instance not initialised yet for acquireTokenSilent.');
            throw new Error('MSAL instance not initialised');
        }

        const account = msalInstance.getAllAccounts()[0];
        if (!account) {
            console.warn('No accounts found for token acquisition.');
            throw new Error('No accounts found');
        }

        try {
            return await msalInstance.acquireTokenSilent({
                scopes: scopes,
                account: account
            }).then(response => {
                return response.accessToken;
            });
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError || error.response?.status === 401) {
                // Redirect the user to login if interaction is required
                console.error("Interaction required for token acquisition:", error);
                signOut();  // Optionally clear the current session
                navigate('/login');  // Redirect to login for a new authentication session
                setLoginError(error.message);
                throw new Error('Redirecting to login due to token acquisition failure');
            } else {
                logError('Error acquiring token silently', error, {}, false);
                setLoginError(error.message);
                throw error;
            }
        }
    };

    /**
     * Function for performing the login redirect.
     */
    const performLogin = async () => {
        msalInstance.loginRedirect({
            scopes: ["openid", "profile"]
        }).catch(e => {
            console.error('Error during login:', e);
            setLoginError(e.message);
            setIsAuthenticating(false);
        });
    };

    /**
     * Memoized context value for the AuthContext.
     */
    const contextValue = useMemo(() => ({
        user, signIn, signOut, isInitialised, acquireTokenSilent, loginError, isAuthenticating
    }), [user, isInitialised, loginError, isAuthenticating, signIn, signOut]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node
};

/**
 * Hook for accessing the AuthContext.
 * @returns {Object} - AuthContext value.
 */
export const useAuth = () => useContext(AuthContext);