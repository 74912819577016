import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Card, Title } from "@tremor/react";

const PerformanceHistogram = ({ theData, theIndex, title }) => {
  // Define the categories (transaction names)
  // This assumes that each entry in the transformed data has the same set of transaction names.
  // You can adjust this logic as needed based on the actual structure of responseTimeDistrib.
  const categories = theData.length > 0 
    ? Object.keys(theData[0]).filter(key => key !== theIndex) 
    : [];

  return (
    <Card>
      <Title>{title}</Title>
      <BarChart
        className="mt-6"
        data={theData}
        index={theIndex}
        categories={categories}
        stack
        colors={['blue', 'yellow', 'pink', 'orange', 'green', 'cyan',  'gray']}
      />
    </Card>
  );
};

PerformanceHistogram.propTypes = {
  theData: PropTypes.array,
  theIndex: PropTypes.string,
  title: PropTypes.string
};

export default PerformanceHistogram;