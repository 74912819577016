import PropTypes from 'prop-types';
import { Card, BarChart, Grid, Title} from "@tremor/react";
import LoadingIcon from "../../navigation/LoadingIcon";

import IssuesList from "../shared/IssuesList";
import { HiBugAnt, HiCodeBracket, HiLockClosed } from 'react-icons/hi2';
import RatingDonut from "../shared/RatingDonut";
import { Chip } from "@material-tailwind/react";
import { severityColourMap } from "../../../datahooks/metadata/DataUtils";

export default function StaticCodeIssues({ issuesData }) {
  
  if (!issuesData) {
    return (
      <Card className="flex justify-center items-center">
        <LoadingIcon showText />
      </Card>
    );
  }
  
  let bugChartData = { name: "Number of Bugs"};
  if (issuesData.bug) {
    Object.entries(issuesData.bug.severityCounts).forEach(([severity, count]) => {
      bugChartData[severity] = count;
    })
  };

  let codeSmellChart = { name: "Number of Code Smells" };

  if (issuesData.code_smell) {
      Object.entries(issuesData.code_smell.severityCounts).forEach(([severity, count]) => {
          codeSmellChart[severity] = count;
      });
  }
  
  let vulnerabilityChart = { name: "Number of Vulnerabilities" };
  
  if (issuesData.vulnerability) {
    Object.entries(issuesData.vulnerability.severityCounts).forEach(([severity, count]) => {
      vulnerabilityChart[severity] = count;
    });
}

  return (
    <>
        <Card className="mb-3">
          <Grid className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center">
              <HiBugAnt size={20} className="mr-2"/> 
              <Title style={{ marginBottom: '0 !important' }}>Bugs</Title>
            </div>
            
            <div className="rating-donut-container flex-row flex items-center" >
              <Chip color="blue" className="mr-3 h-8" value="5 minutes">{issuesData.bug.totalEffort} minutes </Chip> 
              <RatingDonut rating={Number(issuesData.bug.rating)} fixedHeight='50' fixedWidth='50' fontSize="1rem" />
            </div>
          </Grid>

          <BarChart
            className="mt-6"
            data={[bugChartData]}
            index="name"
            categories={Object.keys(severityColourMap)}
            colors={Object.values(severityColourMap)}
            valueFormatter={(value) => value.toString()}
            yAxisWidth={48}
            showAnimation
          />
          
          <IssuesList issues={issuesData.bug.issues} components={issuesData.components} className="w-full" title="Bug List" />

        </Card>
        
        <Card className="mb-3">
          <Grid className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center">
              <HiCodeBracket size={20} className="mr-2"/> 
              <Title>Code Smells</Title>
            </div>
            
            <div className="rating-donut-container flex-row flex" >
              <Chip color="blue" className="mr-3" value={`${issuesData?.code_smell?.totalEffort} minutes`}>{`${issuesData?.code_smell?.totalEffort} minutes `}</Chip>
              <RatingDonut rating={Number(issuesData.code_smell.rating)} fixedHeight='50' fixedWidth='50' fontSize="1rem" />
            </div>
          </Grid>
        
        <BarChart
            className="mt-6"
            data={[codeSmellChart]}
            index="name"
            
            valueFormatter={(value) => value.toString()}
            categories={Object.keys(severityColourMap)}
            colors={Object.values(severityColourMap)} 
            yAxisWidth={48}
            showAnimation
          />
  
        <IssuesList issues={issuesData.code_smell.issues} components={issuesData.components} className="w-full" title={"Code Smells"} />
      </Card>

      <Card className="mb-3">
          <Grid className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center">
              <HiLockClosed size={20} /> 
              <Title>Vulnerabilities</Title>
            </div>
            
            <div className="rating-donut-container flex-row flex" >
              <Chip color="blue" className="mr-3" value={`${issuesData?.vulnerability?.totalEffort} minutes`}>{`${issuesData?.vulnerability?.totalEffort} minutes`} </Chip> 
              <RatingDonut rating={Number(issuesData.vulnerability.rating)} fixedHeight='50' fixedWidth='50' fontSize="1rem" />
            </div>
          </Grid>

        <BarChart
          className="mt-6"
          data={[vulnerabilityChart]}
          index="name"
          categories={Object.keys(severityColourMap)}
          colors={Object.values(severityColourMap)}
          valueFormatter={(value) => value.toString()}
          yAxisWidth={48}
          showAnimation
        />
      
      <IssuesList issues={issuesData.vulnerability.issues} components={issuesData.components} className="w-full" title={"Vulnerabilities"}  />
      </Card>
    </>
  );
}

StaticCodeIssues.propTypes = {
  issuesData: PropTypes.shape({
    bug: PropTypes.shape({
      severityCounts: PropTypes.objectOf(PropTypes.number),
      totalEffort: PropTypes.number,
      rating: PropTypes.number,
      issues: PropTypes.arrayOf(PropTypes.object),
    }),
    code_smell: PropTypes.shape({
      totalEffort: PropTypes.number,
      severityCounts: PropTypes.objectOf(PropTypes.number),
      rating: PropTypes.number,
      issues: PropTypes.arrayOf(PropTypes.object),
    }),
    vulnerability: PropTypes.shape({
      totalEffort: PropTypes.number,
      severityCounts: PropTypes.objectOf(PropTypes.number),
      rating: PropTypes.number,
      issues: PropTypes.arrayOf(PropTypes.object),
    }),
    components: PropTypes.arrayOf(PropTypes.string),
  }),
};