// Utility function to get environment variables from appropriate location
import { getEnvVar } from './datahooks/metadata/EnvUtils';

export const API_BASE_URL = getEnvVar('VITE_API_BASE_URL');
export const API_ADMIN_URL = getEnvVar('VITE_API_ADMIN_URL');
export const FUNCTION_KEY = getEnvVar('VITE_FUNCTION_KEY');

export const PROJECT_SCOPE = getEnvVar('VITE_PROJECT_SCOPE');

export const DC_API_BASE_URL = getEnvVar('VITE_DC_API_BASE_URL');

export const IMAGES_URL = getEnvVar('VITE_IMAGE_BASE_URL');

export const APP_ENVIRONMENT = getEnvVar('VITE_APP_ENVIRONMENT','Production');
export const SENTRY_DSN = getEnvVar('VITE_SENTRY_DSN');

export const IS_DEBUG = getEnvVar('VITE_IS_DEBUG', false);

export const MSAL_RESET_URL = getEnvVar('VITE_MSAL_RESET');

export const MSAL_CONFIG = {
  auth: {
    clientId: getEnvVar('VITE_MSAL_CLIENT_ID'),
    authority: getEnvVar('VITE_MSAL_AUTHORITY'),
    knownAuthorities: [getEnvVar('VITE_MSAL_KNOWN_AUTHORITIES')],
    redirectUri: getEnvVar('VITE_MSAL_REDIRECT_URI'),
    postLogoutRedirectUri: getEnvVar('VITE_MSAL_POST_LOGOUT_REDIRECT_URI')
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const IS_DEV_MODE = APP_ENVIRONMENT === 'Local Development';

// Colour settings
export const COLOURS = {
  pass: '#498E49',   // Same as --green-500
  fail: '#de5d70',   // Same as --highlight
  highlight2: '#ffcf01', // Same as --highlight2
  brand: '#2f6d85',    // Same as --brand-color
};