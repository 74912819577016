import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import useUser from '../../useUser.js';

export default function useProjectTestType(api_id, addingTestType) {
  const { user:userDetails } = useUser();

  const fetchTestType = async (token) => {
    if (addingTestType || api_id === -1) {
      return null; // Don't fetch data if adding a new test type or if the ID is invalid
    }

    try {
      const response = await axios.get(`${API_BASE_URL}disqoadmin/project_test_type?api_id=${api_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        }
      });

      return response.data;

    } catch (error) {
      console.error('Failed to load project test types in useProjectTestType', error); // Error during fetch
      throw error;
    }
  };
  
  const { data: projectTestTypeData = null, isLoading, refetch, error } = useAuthenticatedQuery(`project_test_type_${userDetails?.id}_${api_id}`, fetchTestType, [PROJECT_SCOPE], {
    staleTime: 60000, // Data is considered fresh for 1 minute
    enabled: !addingTestType && api_id !== -1, // Enable the query only if not adding a new test type and ID is valid
  });

  // Additional logs to monitor the state changes
  if (error) {
    console.error('Error fetching project test type:', error);
  }
  
  return { projectTestTypeData, loadingProjectTestType: isLoading, refetchProjectTestTypes: refetch, projectTestTypeError: error };
}

useProjectTestType.propTypes = {
  api_id: PropTypes.number,
  addingTestType: PropTypes.bool
}