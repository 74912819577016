import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button, Typography, Input, IconButton } from '@material-tailwind/react';
import { FaEye, FaEyeSlash, FaCopy } from 'react-icons/fa';
import AlertManager from '../../general/AlertManager';

const UserPasswordDialog = ({ isOpen, onClose, thePassword }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleCopyPassword = () => {
        navigator.clipboard.writeText(thePassword);
        setSuccessMessage('Password copied to clipboard.');
    };

    return (
        <Dialog open={isOpen} handler={onClose} size="sm" className="p-2">
            <div className="flex flex-col items-center space-y-4 p-4 gap-2">
                <Typography variant="h4" className="text-blue-500">Temporary Password</Typography>
                <Typography className="text-center" variant='h6'>
                    Please distribute this password to the user. They will be prompted to change it upon their first login.
                </Typography>
                <Typography variant='h6'>This password will only be displayed once.</Typography>
                <div className="flex items-center space-x-2">
                    <Input
                        type={isPasswordVisible ? "text" : "password"}
                        value={thePassword}
                        readOnly
                        disabled
                        className="text-center text-xl p-2"
                    />
                    <IconButton
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        variant='outlined'
                        color="blue"
                        className='p-3'
                    >
                        {isPasswordVisible ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                    </IconButton>
                    <IconButton
                        onClick={handleCopyPassword}
                        variant="outlined"
                        color="blue"
                        className='p-2'
                    >
                        <FaCopy size={20} />
                    </IconButton>
                </div>

                <AlertManager
                    successMessage={successMessage}
                    setSuccessMessage={setSuccessMessage}
                />

                <Button color="blue" onClick={onClose}>
                    OK
                </Button>
            </div>
        </Dialog>
    );
};

UserPasswordDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    thePassword: PropTypes.string,
};

export default UserPasswordDialog;
