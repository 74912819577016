import React from 'react';
import { useAuth } from '../components/navigation/Authentication.jsx';
import { Button, Alert } from "@material-tailwind/react";

import UnauthenticatedLayout from '../layouts/UnauthenticatedLayout.jsx';
import SpinningTitle from '../components/general/SpinningTitle.jsx';
import { APP_ENVIRONMENT } from '../config.js';

export default function Login() {
    const { signIn, loginError, isAuthenticating } = useAuth(); // loginError is now part of useAuth
   
    const doSignIn = (e) => {
        e.preventDefault();
        signIn(); // signIn is responsible for updating loginError in case of an error
    };

    return (
        <UnauthenticatedLayout>
            <div className="min-h-screen flex items-start justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <img className="mx-auto h-24 w-auto" src="/assets/logo/tiqtoq.png" alt="Workflow" />
                        <SpinningTitle />
                    </div>
                    <Button 
                        color="blue" 
                        size="lg" 
                        onClick={doSignIn} 
                        className="w-full"
                        disabled={isAuthenticating}
                    >
                            {isAuthenticating ? "Logging in..." : "Login"}
                    </Button>
                    {/* Display login error if it exists */}
                    {loginError && <Alert id="errorAlert" color="pink">{loginError}</Alert>}
                    <div>{`Environment: ${APP_ENVIRONMENT}`}</div>
                </div>
            </div>
        </UnauthenticatedLayout>
    );
}