import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Card
} from '@material-tailwind/react';
import LoadingIcon from '../../navigation/LoadingIcon';
import { featureDetails } from '../../../datahooks/metadata/DataUtils';
import FunctionalUIItemHeader from './FunctionalUIItemHeader.jsx';
import FunctionalUIItemDetails from './FunctionalUIItemDetails.jsx';

export default function FunctionalThirdRow ({ projectData }) {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <Card>
      {projectData?.items ? (
        projectData.items.map((test, index) => {
          const detailLink = featureDetails[3].detailLink.replace('{id}', test.id);

          return (
        
          <Accordion key={test.id} open={open === index + 1}>
            <AccordionHeader className="text-base font-normal" onClick={() => handleOpen(index + 1)}>
              <FunctionalUIItemHeader test={test} />
            </AccordionHeader>
            <AccordionBody className='p-3 bg-gray-100 flex flex-col select-none'>
              <FunctionalUIItemDetails test={test} detailLink={detailLink} />
            </AccordionBody>
          </Accordion>
        )})
      ) : (
        <LoadingIcon showText />
      )}
      </Card>
  );
};

FunctionalThirdRow.propTypes = {
  projectData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
        name: PropTypes.string,
        area: PropTypes.string,
        priority: PropTypes.string,
        creation_time: PropTypes.string,
        browser_normalized: PropTypes.string,
        os_normalized: PropTypes.string,
        duration: PropTypes.number
      })
    )
  })
};