import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionHeader, AccordionBody, Typography } from '@material-tailwind/react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { Title, Card } from '@tremor/react';

const ErrorList = ({ title, topErrors }) => {
    const [open, setOpen] = useState(null);

    const handleOpen = (index) => {
        setOpen(open === index ? null : index);
    };

    /*

    <Typography variant='lead' className="pl-3 pr-3 mr-3 flex-grow border-r border-gray-300" style={{ whiteSpace: 'normal' }}>
                                    {page}
                                </Typography>
    */

    return (
        <Card className='mt-6'>
            {title && <Title>{title}</Title>}
            {topErrors && Object.keys(topErrors).length > 0 ? (
                Object.entries(topErrors).map(([page, errorData], index) => (
                    <Accordion key={page} open={open === index + 1}>
                        <AccordionHeader className="text-base font-normal" onClick={() => handleOpen(index + 1)}>
                            <div className="grid items-center w-full" style={{ gridTemplateColumns: '1fr auto auto' }}>
                                <Typography variant='lead' className="pl-3 pr-3 mr-3 flex-grow border-r border-gray-300" style={{ whiteSpace: 'normal' }}>{errorData.error_description}</Typography>
                                <Typography variant='lead' className="pl-3 pr-3 mr-3 flex-grow" style={{ whiteSpace: 'normal' }}>
                                    <span>{(errorData.count / errorData.samples) * 100}%</span>
                                </Typography>
                                <div className="border-l border-gray-300">
                                    {open === index + 1 ? <MdExpandLess size={35} className='ml-3'/> : <MdExpandMore size={35} className='ml-3'/>}
                                </div>
                            </div>
                        </AccordionHeader>
                        <AccordionBody className='p-3 bg-gray-100'>
                            <Typography variant='paragraph'><strong>Page:</strong> {page}</Typography>
                            <Typography variant='paragraph'><strong>Error Count:</strong> {errorData.count}</Typography>
                            <Typography variant='paragraph'><strong>Samples:</strong> {errorData.samples}</Typography>
                            <Typography variant='paragraph'><strong>Error Description:</strong> {errorData.error_description}</Typography>
                        </AccordionBody>
                    </Accordion>
                ))
            ) : (
                <p>No issues to display</p>
            )}
        </Card>
    );
};

ErrorList.propTypes = {
    title: PropTypes.string,
    topErrors: PropTypes.object
};

export default ErrorList;
