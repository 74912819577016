import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid'; // Import UUID generator

/**
 * Generates a random integer between a specified range.
 * As it uses Math.random, it should not be used in security functionality.
 * @param {number} min - The minimum value (inclusive).
 * @param {number} max - The maximum value (inclusive).
 * @returns {number} A random integer between min and max (inclusive).
 */
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * A component to display placeholder text with a specified number of lines and width.
 * Each line may be split into one or two columns to simulate words, using a random generation.
 * This component is styled using Tailwind CSS classes, and animates with a pulsing effect.
 * 
 * The component uses UUIDs for the key values of each line to ensure uniqueness and persistence across re-renders.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {number} [props.lines=1] - The number of lines in the placeholder text.
 * @param {string} [props.width='w-3/4'] - The width of the placeholder text relative to its parent, specified as a Tailwind CSS class.
 * @param {boolean} [props.random=true] - Whether to randomize the number of "columns" in each line.
 * 
 * @returns {JSX.Element} The rendered TextPlaceholder component.
 */
function TextPlaceholder({ lines = 1, width = 'w-3/4', random = true }) {
  const [keys, setKeys] = useState([]); // State to hold generated UUIDs for lines

  /**
   * Effect to generate UUIDs when the `lines` prop changes.
   * This ensures that each line gets a unique key that persists across re-renders.
   */
  useEffect(() => {
    // Generate an array of UUIDs, one for each line
    const generatedKeys = Array.from({ length: lines }).map(() => uuidv4());
    setKeys(generatedKeys); // Store UUIDs in state
  }, [lines]); // Re-run the effect when `lines` changes

  return (
    <div className="animate-pulse">
      {keys.map((key, index) => (
        // Use the generated UUID as the key for each line
        <div key={key} className={`flex space-x-2 mb-4 ${width}`}>
          {Array.from({ length: random ? getRandomInt(1, 3) : 3 }).map((_, colIndex) => (
            // Use the line's UUID and column index to ensure unique keys for each column
            <div key={`${key}-${colIndex}`} className="h-2 bg-gray-400 rounded flex-grow"></div>
          ))}
        </div>
      ))}
    </div>
  );
}

TextPlaceholder.propTypes = {
  lines: PropTypes.number,
  width: PropTypes.string,
  random: PropTypes.bool
};

export default TextPlaceholder;