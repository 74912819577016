import React from 'react';
import PropTypes from 'prop-types';

const FooterLink = ({title, children}) => {
  return (
    <div className=" flex flex-col items-center py-20">
          <h5 className="text-l font-bold mb-4">{title}</h5>
          <div className="border-b-2 border-gray-400 w-1/4 my-10"></div>
            {children}
    </div>
  );
};

FooterLink.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default FooterLink;