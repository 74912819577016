import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Progress } from '@material-tailwind/react';
import { MdExpandMore, MdExpandLess } from "react-icons/md";

const APIHeaderContent = ({ api, open }) => {
  return (
    <div data-testid="api-header-content" className="text-base font-normal grid items-center w-full" style={{ gridTemplateColumns: '25% 1fr minmax(auto, max-content) auto', boxSizing: 'border-box' }}>
      <div className="pl-3 border-r font-normal border-gray-300 break-words" style={{ boxSizing: 'border-box' }} title="Test">
        {api.name}
      </div>
      <div className="pl-3 pr-3 border-r border-gray-300 flex-grow" title={`${api.passRate}% Pass Rate`}>
        <div className="mb-2 flex items-center justify-between gap-4">
          <Typography color="blue-gray" variant="h6">
            Pass rate
          </Typography>
          <Typography color="blue-gray" variant="h6">
            {Math.round(api.passRate.toFixed(2))}%
          </Typography>
        </div>
        <Progress value={Math.round(api?.passRate)} color="green" size="lg" className="bg-pink-500" />
      </div>
      <div className="flex justify-end pl-3 pr-3" title="Number of tests">
        Tests: {api.count}
      </div>
      <div className="border-l border-gray-300">
        {open ? <MdExpandLess data-testid="expand-less-icon" size={35} className='ml-5' /> : <MdExpandMore data-testid="expand-more-icon" size={35} className='ml-5' />}
      </div>
    </div>
  );
};

APIHeaderContent.propTypes = {
  api: PropTypes.shape({
    name: PropTypes.string.isRequired,
    passRate: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
};

export default APIHeaderContent;
