import React from 'react';
import PropTypes from 'prop-types';

import { VscError } from "react-icons/vsc";
import { MdOutlineRunningWithErrors } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";

export const CARD_TYPES = {
    EMPTY: 'empty',
    ERROR: 'error',
    RUNNING: 'running',
  };

export function ErrorCard({ cardType }) {
    let background, IconComponent, colour;

    switch (cardType) {
        case CARD_TYPES.ERROR:
            background = '/assets/icons/error_back.svg';
            IconComponent = VscError;
            colour='pink'
            break;
        case CARD_TYPES.EMPTY:
            background = '/assets/icons/warn_back.svg';
            IconComponent = IoWarningOutline;
            colour='orange'
            break;
        case CARD_TYPES.RUNNING:
            background = '/assets/icons/warn_back.svg';
            IconComponent = MdOutlineRunningWithErrors;
            colour = 'orange';
            break;
        default:
            background = '/assets/icons/default_back.svg'; // A default background if needed
            IconComponent = IoWarningOutline; // Default icon
            colour = 'orange';
    }

  return (
    <div className="relative bg-center bg-contain bg-no-repeat w-full h-28 max-w-md mx-auto" 
         style={{ backgroundImage: `url(${background})` }}>

      <div className="absolute inset-0 flex justify-center items-center">
        <IconComponent size={70} className='text-blue' color={colour} />
      </div>
      
    </div>
  );
}

ErrorCard.propTypes = {
    cardType: PropTypes.oneOf(Object.values(CARD_TYPES)),
  };
