// DisableDialog.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button, DialogBody, DialogFooter, DialogHeader, IconButton } from '@material-tailwind/react';
import { FaStopCircle, FaPlayCircle } from "react-icons/fa";

const DisableDialog = ({theId, theStatus, theType, onConfirm, showText=true}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => setIsOpen(!isOpen);

    const handleConfirm = () => {
        //Call the update function passed from parent with ID and new status
        const newStatus = theStatus === 'active' ? 'inactive' : 'active';
        onConfirm(theId, newStatus);
        handleToggle();     // Close the dialog
    };

    const getStatusIcon = () => {
        // Return the appropriate icon based on the current status
        return theStatus === 'active' ? <FaStopCircle size={20} /> : <FaPlayCircle size={20} />;
      };

    return (
        <>
            {showText ? (
            <Button size="sm" variant="outlined" className="flex text-blue-500 items-center gap-3" onClick={() => handleToggle()}>
                { getStatusIcon() } {theStatus == 'active' ? 'Disable' : 'Enable'}
            </Button>
            ) : (
                <IconButton color='blue' variant='text' onClick={() => handleToggle()} alt={`${theStatus == 'active' ? 'Disable' : 'Enable'} ${theType}?`}>{ getStatusIcon() }</IconButton>
            )}

            <Dialog open={isOpen} handler={handleToggle}>
                <DialogHeader>{`${theStatus == 'active' ? 'Disable' : 'Enable'} ${theType}?`}</DialogHeader>
                <DialogBody className='text-base'>{`Are you sure you want to ${theStatus == 'active' ? 'disable' : 'enable'} this ${theType}?`}</DialogBody>
                <DialogFooter className='flex justify-end gap-3'>
                    <Button color='pink' onClick={handleToggle}>Cancel</Button>
                    <Button color='blue' onClick={handleConfirm}>Confirm</Button>
                </DialogFooter>
            </Dialog>
        </>
    );
};

DisableDialog.propTypes = {
    theId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    theStatus: PropTypes.string,
    theType: PropTypes.string,
    onConfirm: PropTypes.func,
    showText: PropTypes.bool
};

export default DisableDialog;