//Project manager provides a hook that performs different mutations on 
//project data and handles multiple methods for mutating the data.
import useAuthenticatedMutation from '../useAuthenticatedMutation';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_ADMIN_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config';
import logError from '../../metadata/ErrorLogging';

// Function to perform HTTP requests
const manageUserAccess = async ({data, method}, token) => {
    let url = `${API_ADMIN_URL}user_project_perms`;
    
    if (method === 'DELETE' && data.del_user_id && data.del_project_id) {
        url += `?del_user_id=${data.del_user_id}&del_project_id=${data.del_project_id}`; // Add to URL for DELETE
    }

    try {
        const response = await axios({
            method: method,
            url: url,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-functions-key': FUNCTION_KEY,
            },
            data: method !== 'DELETE' ? data : {}, // Only send data for non-DELETE requests
        });

        return response.data;
    } catch (error) {
        const errorMessage = `Failed to ${method.toLowerCase()} user project access data: ${error.message}`;
        const responseBody = (error?.response?.data ? error.response.data : "");

        logError(errorMessage, error, { "URL": url , "Data": data , "responseBody": responseBody, "method": method, "errorMessage": errorMessage });

        throw error;
    }
};

// Hook to manage organisations
export default function useUserProjectAccessManager(onSuccess, onError) {
    
    const projectScope = [PROJECT_SCOPE];
    const { mutate, isLoading, isError, error, data, reset: resetMutation } = useAuthenticatedMutation(manageUserAccess, projectScope, {
        onSuccess,
        onError
    });

    // Function to call the mutate function with additional parameters for different HTTP methods
    const performAction = (data, method = 'POST') => {
        // Structure the data here to include the method, the token is added by the mutation function
        mutate({
            data: data,
            method: method
        });
    };

    return {
        performAction,
        isLoading,
        isError,
        error,
        data,
        reset: resetMutation
    };
}

useUserProjectAccessManager.propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func
}