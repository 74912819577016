import React from 'react';
import PropTypes from 'prop-types';
import { DonutChart } from "@tremor/react";

import OverviewCard from './OverviewCard';

export default function Overview_FUI({ overviewData, icon, title, PopoverComponent}) {

    let fuiData=[];

    if (overviewData) {
        fuiData = [
            { name: "Passed", count: overviewData.passedTests },
            { name: "Failed", count: overviewData.totalTests - overviewData.passedTests }
        ];
    }

    return (
                <OverviewCard
                    Icon={icon}
                    title={title}
                    PopoverComponent={PopoverComponent}
                    ChartComponent={<DonutChart
                        data={fuiData}
                        category="count"
                        index="name"
                        colors={["green", "pink"]}
                        label={`${Math.round((overviewData?.passedTests / overviewData?.totalTests) * 100)}%`}
                        showAnimation
                        style={{ fontSize: '2rem' }}
                        />}
                    additionalInfo="Total Tests"
                    additionalMetric={overviewData?.totalTests}
                    loading={!overviewData}
                    runtime={overviewData?.runtime}
                />
        );   
};

Overview_FUI.propTypes = {
    overviewData: PropTypes.shape({
        passedTests: PropTypes.number,
        totalTests: PropTypes.number,
        runtime: PropTypes.string
    }),
    icon: PropTypes.element,
    title: PropTypes.string,
    PopoverComponent: PropTypes.elementType
};