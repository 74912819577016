import React from "react";
import PropTypes from 'prop-types';
import { Card, Metric  } from "@tremor/react";
import LoadingIcon from "../../navigation/LoadingIcon";
import { Typography, Chip } from "@material-tailwind/react";
import { MdOutlineTimer } from "react-icons/md";

export default function OverviewCard({ displayMessage, Icon, title, PopoverComponent, ChartComponent, additionalInfo, additionalMetric, loading, onClick, runtime }) {
    const iconWithProps = Icon ? React.cloneElement(Icon, {
        className: "mr-2",
        size: 25,
    }) : null;

    const pointerClass = (onClick) ? "cursor-pointer" : "";
    const clickProps = onClick ? { onClick: onClick } : {};
    
    return (
        <Card className="flex flex-col justify-center items-center h-full sm:p-3">
            {loading ? (
                <LoadingIcon showText />
            ) : (
                <div className="flex flex-col flex-grow w-full">
                    <Typography
                        className="text-blue-500 font-medium flex flex-col grey-title-border pb-2 mb-2"
                        variant="h5"
                        >
                        <div className="flex flex-row justify-between items-center">
                            <div className={`flex flex-row items-center ${pointerClass}`} {...clickProps}>
                            {iconWithProps}
                            {title}
                            </div>
                            <PopoverComponent />
                        </div>

                        <div className="flex flex-row justify-start">
                            {runtime && (
                            <Chip
                                color='blue'
                                value={runtime}
                                variant="outlined"
                                icon={<MdOutlineTimer size={20}/>}
                            />
                            )}
                        </div>
                    </Typography>

                    {displayMessage ? (
                        <div className="flex flex-col justify-center ">
                            {displayMessage}
                        </div>
                    ) : (
                        <div className={`flex flex-row pt-2 ${pointerClass}`} {...clickProps} >
                            <div className="flex-1 flex items-center justify-center sm:pr-2 pr-10">
                                {ChartComponent}
                            </div>
                            <div className={`border-l-2 flex-1 flex flex-col justify-center text-center sm:pl-2 pl-10 ${pointerClass}`}>
                                {// <Title>{additionalInfo}</Title>
                                }
                                <Typography variant="h5" className="text-blue-500 font-medium">
                                    {additionalInfo}
                                </Typography>
                                <Metric className="metric-auto-height">{additionalMetric}</Metric> 
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Card>
    );
};

OverviewCard.propTypes = {
    displayMessage: PropTypes.node,
    Icon: PropTypes.element,
    title: PropTypes.string,
    PopoverComponent: PropTypes.elementType,
    ChartComponent: PropTypes.element,
    additionalInfo: PropTypes.string,
    additionalMetric: PropTypes.node,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    runtime: PropTypes.string,
};