import React from 'react';
import PropTypes from 'prop-types';
import { DonutChart } from "@tremor/react";

import OverviewCard from './OverviewCard';

export default function Overview_ACC({ overviewData, icon, title, PopoverComponent}) {
    let accessibilityLabel = "0%";
    let accessibilityData = [];

    if (overviewData) {
        accessibilityData = [
            { name: "Passed", count: overviewData?.passed },
            { name: "Failed", count: overviewData?.failed }
        ];
    }

    if (overviewData?.failed + overviewData?.passed > 0) {
        accessibilityLabel = Math.round((1 - (overviewData.passed / (overviewData.failed + overviewData.passed))) * 100) + "%";
    }

    return (
                <OverviewCard
                    Icon={icon}
                    title={title}
                    PopoverComponent={PopoverComponent}
                    ChartComponent={<DonutChart
                        data={accessibilityData}
                        category="count"
                        index="name"
                        colors={["green", "pink"]}
                        label={accessibilityLabel}
                        showAnimation
                        style={{ fontSize: '2rem' }}
                        />}
                    additionalInfo="Total Pages Tested"
                    additionalMetric={overviewData?.passed + overviewData?.failed}
                    loading={!overviewData}
                    runtime={overviewData?.runtime}
                />
        );   
};

Overview_ACC.propTypes = {
    overviewData: PropTypes.shape({
        passed: PropTypes.number,
        failed: PropTypes.number,
        runtime: PropTypes.string
    }),
    icon: PropTypes.element,
    title: PropTypes.string,
    PopoverComponent: PropTypes.elementType
};