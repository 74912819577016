import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useOrganisation from './useOrganisation';
import { useAuth } from '../../../components/navigation/Authentication';

const OrganisationContext = createContext();

export const useOrgContext = () => useContext(OrganisationContext);

export const OrganisationProvider = ({ children }) => {
    const { user } = useAuth(); // Get the authenticated user from the Auth context
    const [editingOrganisationId, setEditingOrganisationId] = useState(() => {
        return localStorage.getItem('editingOrganisationId') || null;
      });
    const [userOrganisation, setUserOrganisation] = useState(null);

    // Gets the organisation data. If editingOrganisationId is null, it fetches the user's own organisation.
    const { orgData: organisationData, loading, refetch, error } = useOrganisation(editingOrganisationId);

    useEffect(() => {
        if (!user) return; // Early return if user is not authenticated

        if (!editingOrganisationId && organisationData && Object.keys(organisationData).length > 0 && !userOrganisation) {
            setUserOrganisation(organisationData);  // Store user's own org data
        }
    }, [user, editingOrganisationId, organisationData, userOrganisation]);
    

    // Function to change the active organisation
    const changeOrganisation = (newOrganisationID) => {
        setEditingOrganisationId(newOrganisationID);
    };
    
    // Reset active organisation to the user's own organisation
    const resetOrganisation = () => {
        setEditingOrganisationId(null);
    };

    useEffect(() => {
        if (editingOrganisationId) {
          localStorage.setItem('editingOrganisationId', editingOrganisationId);
        } else {
          localStorage.removeItem('editingOrganisationId');
        }
      }, [editingOrganisationId]);

    // Memoize the context value to prevent unnecessary re-renders
    const contextValue = useMemo(() => ({
        organisationData,
        userOrganisation,  // User's own organisation - could be different from the active organisation if the user is a super admin
        changeOrganisation,
        resetOrganisation,
        refetchOrganisation: refetch,
        loadingOrganisation: loading,
        organisationError: error ? (error.message || 'An unknown error occurred') : null
    }), [organisationData, userOrganisation, changeOrganisation, resetOrganisation, refetch, loading, error]);

    return (
        <OrganisationContext.Provider value={contextValue}>
            {children}
        </OrganisationContext.Provider>
    );
};

OrganisationProvider.propTypes = {
    children: PropTypes.node,
};
