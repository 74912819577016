import PopExplanation from './PopExplanation';
import PropTypes from 'prop-types';
export default function PopPerformance({ page = false, size=35 }) {

    return (
        <PopExplanation 
            title="Performance" 
            size={size}
            metric1Title={page ? "" : "Pass Rate"}
            metric1Desc={page ? "Measures how fast and efficiently the app runs. Looks at speed and how well it uses resources, important for a smooth experience.Different text for metric1Desc when page is true" : "Represents the performance pass rate, where a high percentage denotes robust and reliable performance, and a low percentage indicates potential issues or inconsistencies in the system's performance."}
            metric2Title={page ? "" : "Transactions"}
            metric2Desc={page ? "" : "Reflects the total number of distinct user interactions or operations tested on the application or website."} />
    );
}

PopPerformance.propTypes = {
    page: PropTypes.bool,
    size: PropTypes.number
}