import React from 'react';
import { AiFillFacebook, AiFillLinkedin } from 'react-icons/ai';
import { FaXTwitter, FaSquareThreads } from "react-icons/fa6";
import FooterBlock from './FooterBlock';
import FooterLink from './FooterLink';

const Footer = () => {
  return (
    <footer className="bg-white text-gray-800 border-t mt-4">
      <div className="mx-auto px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* First two FooterBlocks */}
        <FooterBlock title="Company">
          <FooterLink url="https://tiqtoq.co.uk/about">About tiQtoQ</FooterLink>
          <FooterLink url="https://tiqtoq.co.uk/values">Values</FooterLink>
          <FooterLink url="https://tiqtoq.co.uk/clients">Clients</FooterLink>
          <FooterLink url="https://tiqtoq.co.uk/careers">Careers</FooterLink>
          <FooterLink url="https://tiqtoq.co.uk/blog">Blog</FooterLink>
          <FooterLink url="https://tiqtoq.co.uk/contact-us">Contact us</FooterLink>
        </FooterBlock>
        <FooterBlock title="Services">
          <FooterLink url="https://tiqtoq.co.uk/services">Overview</FooterLink>
          <FooterLink url="https://tiqtoq.co.uk/test-quality-assurance-assessment">Test & Quality Assurance Assessment</FooterLink>
          <FooterLink url="https://tiqtoq.co.uk/managed-services">Managed Services</FooterLink>
          <FooterLink url="https://tiqtoq.co.uk/automation-framework">Automation Framework</FooterLink>
          <FooterLink url="https://tiqtoq.co.uk/resource-augmentation">Resource Augmentation</FooterLink>
          <FooterLink url="https://tiqtoq.co.uk/permanent-resource-mobilisation">Permanent Resource Mobilisation</FooterLink>
        </FooterBlock>  
        <div className="lg:col-span-2 bg-gray-100 grid grid-cols-2">
        <FooterBlock title="Contact Us">
            <FooterLink url="tel:+443333355754" className="text-gray-800">0333 3355754</FooterLink>
            <FooterLink url="mailto:enquiries@tiqtoq.co.uk" className="text-gray-800">enquiries@tiqtoq.co.uk</FooterLink>
            <div className="flex space-x-4 my-5 ">
                <FooterLink url="https://www.linkedin.com/company/11032412">
                    <AiFillLinkedin style={{ color: "#0077b5" }} size={35} aria-label="LinkedIn" />
                </FooterLink>
                <FooterLink url="https://www.twitter.com/tiqtoqassurance">
                    <div className="bg-black p-1 inline-block">
                        <FaXTwitter size={20} className="text-white" aria-label="Twitter" />
                    </div>
                </FooterLink>
                <FooterLink url="https://www.facebook.com/tiQtoQSQA">
                    <AiFillFacebook style={{ color: "#316FF6" }} size={35} aria-label="Facebook" />
                </FooterLink>
                <FooterLink url="https://www.threads.net/">
                    <FaSquareThreads size={35} aria-label="Threads" />
                </FooterLink>
            </div>
        </FooterBlock>

            <FooterBlock title="Legal">
                <FooterLink url="https://tiqtoq.co.uk/wp-content/uploads/2022/02/tiQtoQ-data-protection-policy.pdf">Data Protection Policy</FooterLink>
                <FooterLink url="https://tiqtoq.co.uk/cookie-policy">Our Cookie Policy</FooterLink>
                <iframe
                    src="https://registry.blockmarktech.com/certificates/8a8b843b-01e1-4bc1-a3a6-1baeef05cac8/widget/?tooltip_position=bottom_right&theme=transparent&hover=true"
                    style={{ border: 'none', height: '100px', width: '100px', margin: 'auto', marginTop: '10px', display: 'block' }}
                    title="Cyber Essentials Plus Accreditation"
                    loading="lazy"
                    referrerPolicy="no-referrer"
                ></iframe>
                <FooterLink url="https://smeclimatehub.org/">
                    <img
                        src="https://tiqtoq.co.uk/wp-content/uploads/2023/08/2023-SME-Committed-Badge.png" 
                        alt="SME Committed Badge" 
                        className="mt-4 w-[121px] h-[50px]"
                    />
                </FooterLink>
                <div className="bg-gray-100 text-center text-xs font-light py-4 mt-8">
                    <p>Registered Office:</p>
                    <p>Mornington, Drope Road, Cardiff, CF5 6EP</p>
                    <p>Company Number: 10168834</p>
                    <p>VAT Number: GB26558661</p>
                </div>
                <p className='font-light'>Copyright © 2023 tiQtoQ Ltd</p>
            </FooterBlock>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
