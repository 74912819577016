import { useEffect, useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MSAL_CONFIG } from '../config';

// Create a module-level variable to store the MSAL instance to avoid recreating frequently
let msalInstance = null;

export const useMsal = () => {
    const [isInitialised, setIsInitialised] = useState(false);
    
    useEffect(() => {
        if (!msalInstance) {
            msalInstance = new PublicClientApplication(MSAL_CONFIG);
            
            // Initialise the MSAL instance
            msalInstance.initialize().then(() => {
                setIsInitialised(true);
            }).catch((error) => {
                console.error('Error initializing MSAL instance:', error);
            });
        } else {
            // If the instance already exists, we consider it as initialised
            setIsInitialised(true);
        }

        // Cleanup function in case the component unmounts
        return () => {
  
        };
    }, []);

    return { msalInstance, isInitialised };
};