import React from 'react';

import AdminLayoutOrganisation from '../../layouts/AdminLayoutOrganisation';
import Projects from '../../components/admin/project/Projects';

export default function AdminProjects() {

  return (
    <AdminLayoutOrganisation>
      <div className="mt-2 md:mt-1 p-0 md:p-6 bg-gray-50 rounded-lg border ">
          <Projects />
      </div>
      
    </AdminLayoutOrganisation>
  );
}